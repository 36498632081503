import {
  API,
  LOCAL_STORAGE_KEY,
  PATTERN,
  WEBVIEW_ACTION,
  REGEX,
} from "../config/config";
import axios from "axios";
export const sendMessage = (data) => {
  setTimeout(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }, 1);
};

export const setAxiosToken = async (token) => {
  axios.defaults.headers.common["Authorization"] = token;
};

export const setDarkMode = (darkMode) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.DARK_MODE, darkMode);
};

export const sortArrayBySize = (arr, size) => {
  return arr.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
    []
  );
};

export const getNewAreas = (areas) => {
  const newAreas = areas.reduce((acc, cur) => {
    acc[cur.Id] = { Name: cur.Name };
    return acc;
  }, {});
  return newAreas;
};

export const openUrl = (url, onApp) => {
  let newWebsite =
    url.includes(PATTERN.HTTP) || url.includes(PATTERN.HTTPS)
      ? url
      : `${PATTERN.HTTP}${url}`;
  //
  if (newWebsite) {
    if (onApp) {
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_WEBSITE,
        url: newWebsite,
      });
    } else {
      //console.log("Send Message");
      window.open(newWebsite);
    }
  }
};
export const validateUrl = (websiteLink) => {
  let urlRegex = new RegExp(REGEX.URL);
  const isValidUrl = urlRegex.test(websiteLink);
  return isValidUrl;
};
export const trimValue = (value) => {
  return value.replace(/\s+/g, "");
};
export const getLocaleDateString = () => {
  var formats = {
    "ar-SA": "DD/MM/YYYY",
    "bg-BG": "DD/MM/YYYY",
    "ca-ES": "DD/MM/YYYY",
    "zh-TW": "YYYY/MM/DD",
    "cs-CZ": "DD/MM/YYYY",
    "da-DK": "DD/MM/YYYY",
    "de-DE": "DD/MM/YYYY",
    "el-GR": "DD/MM/YYYY",
    "en-US": "MM/DD/YYYY",
    "fi-FI": "DD/MM/YYYY",
    "fr-FR": "DD/MM/YYYY",
    "he-IL": "DD/MM/YYYY",
    "hu-HU": "YYYY/MM/DD",
    "is-IS": "DD/MM/YYYY",
    "it-IT": "DD/MM/YYYY",
    "ja-JP": "YYYY/MM/DD",
    "ko-KR": "YYYY/MM/DD",
    "nl-NL": "DD/MM/YYYY",
    "nb-NO": "DD/MM/YYYY",
    "pl-PL": "YYYY/MM/DD",
    "pt-BR": "DD/MM/YYYY",
    "ro-RO": "DD/MM/YYYY",
    "ru-RU": "DD/MM/YYYY",
    "hr-HR": "DD/MM/YYYY",
    "sk-SK": "DD/MM/YYYY",
    "sq-AL": "YYYY/MM/DD",
    "sv-SE": "YYYY/MM/DD",
    "th-TH": "DD/MM/YYYY",
    "tr-TR": "DD/MM/YYYY",
    "ur-PK": "DD/MM/YYYY",
    "id-ID": "DD/MM/YYYY",
    "uk-UA": "DD/MM/YYYY",
    "be-BY": "DD/MM/YYYY",
    "sl-SI": "DD/MM/YYYY",
    "et-EE": "DD/MM/YYYY",
    "lv-LV": "YYYY/MM/DD",
    "lt-LT": "YYYY/MM/DD",
    "fa-IR": "MM/DD/YYYY",
    "vi-VN": "DD/MM/YYYY",
    "hy-AM": "DD/MM/YYYY",
    "az-Latn-AZ": "DD/MM/YYYY",
    "eu-ES": "YYYY/MM/DD",
    "mk-MK": "DD/MM/YYYY",
    "af-ZA": "YYYY/MM/DD",
    "ka-GE": "DD/MM/YYYY",
    "fo-FO": "DD/MM/YYYY",
    "hi-IN": "DD/MM/YYYY",
    "ms-MY": "DD/MM/YYYY",
    "kk-KZ": "DD/MM/YYYY",
    "ky-KG": "DD/MM/YYYY",
    "sw-KE": "MM/DD/YYYY",
    "uz-Latn-UZ": "DD/MM/YYYY",
    "tt-RU": "DD/MM/YYYY",
    "pa-IN": "DD/MM/YYYY",
    "gu-IN": "DD/MM/YYYY",
    "ta-IN": "DD/MM/YYYY",
    "te-IN": "DD/MM/YYYY",
    "kn-IN": "DD/MM/YYYY",
    "mr-IN": "DD/MM/YYYY",
    "sa-IN": "DD/MM/YYYY",
    "mn-MN": "YYYY/MM/DD",
    "gl-ES": "DD/MM/YYYY",
    "kok-IN": "DD/MM/YYYY",
    "syr-SY": "DD/MM/YYYY",
    "dv-MV": "DD/MM/YYYY",
    "ar-IQ": "DD/MM/YYYY",
    "zh-CN": "YYYY/MM/DD",
    "de-CH": "DD/MM/YYYY",
    "en-GB": "DD/MM/YYYY",
    "es-MX": "DD/MM/YYYY",
    "fr-BE": "DD/MM/YYYY",
    "it-CH": "DD/MM/YYYY",
    "nl-BE": "DD/MM/YYYY",
    "nn-NO": "DD/MM/YYYY",
    "pt-PT": "DD/MM/YYYY",
    "sr-Latn-CS": "DD/MM/YYYY",
    "sv-FI": "DD/MM/YYYY",
    "az-Cyrl-AZ": "DD/MM/YYYY",
    "ms-BN": "DD/MM/YYYY",
    "uz-Cyrl-UZ": "DD/MM/YYYY",
    "ar-EG": "DD/MM/YYYY",
    "zh-HK": "DD/MM/YYYY",
    "de-AT": "DD/MM/YYYY",
    "en-AU": "DD/MM/YYYY",
    "es-ES": "DD/MM/YYYY",
    "fr-CA": "YYYY/MM/DD",
    "sr-Cyrl-CS": "DD/MM/YYYY",
    "ar-LY": "DD/MM/YYYY",
    "zh-SG": "DD/MM/YYYY",
    "de-LU": "DD/MM/YYYY",
    "en-CA": "DD/MM/YYYY",
    "es-GT": "DD/MM/YYYY",
    "fr-CH": "DD/MM/YYYY",
    "ar-DZ": "DD/MM/YYYY",
    "zh-MO": "DD/MM/YYYY",
    "de-LI": "DD/MM/YYYY",
    "en-NZ": "DD/MM/YYYY",
    "es-CR": "DD/MM/YYYY",
    "fr-LU": "DD/MM/YYYY",
    "ar-MA": "DD/MM/YYYY",
    "en-IE": "DD/MM/YYYY",
    "es-PA": "MM/DD/YYYY",
    "fr-MC": "DD/MM/YYYY",
    "ar-TN": "DD/MM/YYYY",
    "en-ZA": "YYYY/MM/DD",
    "es-DO": "DD/MM/YYYY",
    "ar-OM": "DD/MM/YYYY",
    "en-JM": "DD/MM/YYYY",
    "es-VE": "DD/MM/YYYY",
    "ar-YE": "DD/MM/YYYY",
    "en-029": "MM/DD/YYYY",
    "es-CO": "DD/MM/YYYY",
    "ar-SY": "DD/MM/YYYY",
    "en-BZ": "DD/MM/YYYY",
    "es-PE": "DD/MM/YYYY",
    "ar-JO": "DD/MM/YYYY",
    "en-TT": "DD/MM/YYYY",
    "es-AR": "DD/MM/YYYY",
    "ar-LB": "DD/MM/YYYY",
    "en-ZW": "MM/DD/YYYY",
    "es-EC": "DD/MM/YYYY",
    "ar-KW": "DD/MM/YYYY",
    "en-PH": "MM/DD/YYYY",
    "es-CL": "DD/MM/YYYY",
    "ar-AE": "DD/MM/YYYY",
    "es-UY": "DD/MM/YYYY",
    "ar-BH": "DD/MM/YYYY",
    "es-PY": "DD/MM/YYYY",
    "ar-QA": "DD/MM/YYYY",
    "es-BO": "DD/MM/YYYY",
    "es-SV": "DD/MM/YYYY",
    "es-HN": "DD/MM/YYYY",
    "es-NI": "DD/MM/YYYY",
    "es-PR": "DD/MM/YYYY",
    "am-ET": "DD/MM/YYYY",
    "tzm-Latn-DZ": "DD/MM/YYYY",
    "iu-Latn-CA": "DD/MM/YYYY",
    "sma-NO": "DD/MM/YYYY",
    "mn-Mong-CN": "YYYY/MM/DD",
    "gd-GB": "DD/MM/YYYY",
    "en-MY": "DD/MM/YYYY",
    "prs-AF": "DD/MM/YYYY",
    "bn-BD": "DD/MM/YYYY",
    "wo-SN": "DD/MM/YYYY",
    "rw-RW": "MM/DD/YYYY",
    "qut-GT": "DD/MM/YYYY",
    "sah-RU": "MM.dd.yyyy",
    "gsw-FR": "DD/MM/YYYY",
    "co-FR": "DD/MM/YYYY",
    "oc-FR": "DD/MM/YYYY",
    "mi-NZ": "DD/MM/YYYY",
    "ga-IE": "DD/MM/YYYY",
    "se-SE": "YYYY/MM/DD",
    "br-FR": "DD/MM/YYYY",
    "smn-FI": "DD/MM/YYYY",
    "moh-CA": "MM/DD/YYYY",
    "arn-CL": "DD/MM/YYYY",
    "ii-CN": "YYYY/MM/DD",
    "dsb-DE": "DD/MM/YYYY",
    "ig-NG": "DD/MM/YYYY",
    "kl-GL": "DD/MM/YYYY",
    "lb-LU": "DD/MM/YYYY",
    "ba-RU": "DD/MM/YYYY",
    "nso-ZA": "YYYY/MM/DD",
    "quz-BO": "DD/MM/YYYY",
    "yo-NG": "DD/MM/YYYY",
    "ha-Latn-NG": "DD/MM/YYYY",
    "fil-PH": "MM/DD/YYYY",
    "ps-AF": "DD/MM/YYYY",
    "fy-NL": "DD/MM/YYYY",
    "ne-NP": "MM/DD/YYYY",
    "se-NO": "DD/MM/YYYY",
    "iu-Cans-CA": "DD/MM/YYYY",
    "sr-Latn-RS": "DD/MM/YYYY",
    "si-LK": "YYYY/MM/DD",
    "sr-Cyrl-RS": "DD/MM/YYYY",
    "lo-LA": "DD/MM/YYYY",
    "km-KH": "YYYY/MM/DD",
    "cy-GB": "DD/MM/YYYY",
    "bo-CN": "YYYY/MM/DD",
    "sms-FI": "DD/MM/YYYY",
    "as-IN": "DD/MM/YYYY",
    "ml-IN": "DD/MM/YYYY",
    "en-IN": "DD/MM/YYYY",
    "or-IN": "DD/MM/YYYY",
    "bn-IN": "DD/MM/YYYY",
    "tk-TM": "DD/MM/YYYY",
    "bs-Latn-BA": "DD/MM/YYYY",
    "mt-MT": "DD/MM/YYYY",
    "sr-Cyrl-ME": "DD/MM/YYYY",
    "se-FI": "DD/MM/YYYY",
    "zu-ZA": "YYYY/MM/DD",
    "xh-ZA": "YYYY/MM/DD",
    "tn-ZA": "YYYY/MM/DD",
    "hsb-DE": "DD/MM/YYYY",
    "bs-Cyrl-BA": "DD/MM/YYYY",
    "tg-Cyrl-TJ": "DD/MM/YYYY",
    "sr-Latn-BA": "DD/MM/YYYY",
    "smj-NO": "DD/MM/YYYY",
    "rm-CH": "DD/MM/YYYY",
    "smj-SE": "YYYY/MM/DD",
    "quz-EC": "DD/MM/YYYY",
    "quz-PE": "DD/MM/YYYY",
    "hr-BA": "DD/MM/YYYY",
    "sr-Latn-ME": "DD/MM/YYYY",
    "sma-SE": "YYYY/MM/DD",
    "en-SG": "DD/MM/YYYY",
    "ug-CN": "YYYY/MM/DD",
    "sr-Cyrl-BA": "DD/MM/YYYY",
    "es-US": "MM/DD/YYYY",
  };

  return formats[navigator.language] || "DD/MM/YYYY";
};
