import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createMuiTheme } from "@material-ui/core/styles";
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { API } from "../../config/config";
import { sortArrayBySize } from "../../ultils/ultils";
import CategoryItem from "./Items/CategoryItem";
export default function GigCategories({ onForeGround }) {
  const [gigCategories, setGigCategoires] = useState([]);
  const [isAllow, setIsAllow] = useState(true);
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        setIsAllow(true);
      }, 1000);
    }
  }, [isAllow]);
  useEffect(() => {
    const getAllCategories = async () => {
      const categories = await axios.get(API.GET_ALL_JOB_CATEGORIES, {}, {});
      let resultArray = sortArrayBySize(categories.data, 2);
      console.log({ resultArray });
      setGigCategoires(resultArray);
    };
    if (onForeGround) {
      getAllCategories();
    }
  }, [onForeGround]);
  return (
    <Fragment>
      <h2 style={{ marginTop: 0 }}>Gig Categories</h2>
      <ul className="SideCategories">
        {gigCategories.length > 0 &&
          gigCategories.map((item) => <CategoryItem category={item} />)}
      </ul>
    </Fragment>
  );
}
