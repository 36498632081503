import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import routes from "./routes/routes";
import DateFnsUtils from "@date-io/date-fns";
import "./css/style.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "slick-carousel/slick/slick.css";
import App from "./App";
let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#832CFF",
    },
    secondary: {
      main: "#f44336",
    },
    background: {
      default: "#f5f5f5",
    },
  },

  typography: {
    fontFamily: "Montserrat, Arial",
    fontWeightRegular: 500,
    fontSize: 13.5,
    lineHeight: 1.5,
  },
});

const store = configureStore();

const app = (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {routes}
      </MuiPickersUtilsProvider>
      {/* {routes} */}
      <NotificationContainer />
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
