import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import About from "../containers/About";
import ChangePassword from "../containers/ChangePassword";
import CommunityStandards from "../containers/CommunityStandards";
import EULA from "../containers/Components/EULA";
import Dashboard from "../containers/Dashboard";
import FAQ from "../containers/FAQ/FAQ";
import FollowPage from "../containers/FollowPages/FollowPage";
import GigCategoriesList from "../containers/GigCategoriesList";
import MyGigs from "../containers/GigsTab/MyGigs";
import HomeRoute from "../containers/HomeRoute";
import JobBrowsing from "../containers/JobBrowsing";
import JobDetails from "../containers/JobDetails";
import LikedGigsComponent from "../containers/LikedGigs/LikedGigsComponent";
import Login from "../containers/Login";
import NoInternet from "../containers/NoInternet";
import PageNotFound from "../containers/PageNotFound";
import PostJob from "../containers/PostJob";
import UserProfile from "../containers/UserProfile";
import UserProfileReview from "../containers/UserProfile/UserProfileReview";

export default (
  <Router>
    <div>
      <Route exact path="/" component={HomeRoute} />
      <Route exact path="/Gigs" component={JobBrowsing} />
      {/* <Route exact path="/search" component={TestSearch} /> */}
      <Route exact path="/PostJob" component={PostJob} />
      <Route exact path="/user/:username?" component={UserProfile} />
      <Route exact path="/Login" component={Login} />
      <Route exact path="/MyGigs" component={MyGigs} />
      <Route exact path="/Gigs/Details/:jobCode" component={JobDetails} />
      <Route exact path="/Dashboard" component={Dashboard} />
      <Route exact path="/gigsByCategory/:id" component={GigCategoriesList} />
      <Route exact path="/About" component={About} />
      <Route exact path="/ChangePassword" component={ChangePassword} />
      <Route exact path="/eula" component={EULA} />
      <Route exact path="/Faq" component={FAQ} />
      <Route exact path="/Community" component={CommunityStandards} />
      <Route exact path="/Follow/:username/:keyword" component={FollowPage} />
      <Route
        exact
        path="/user/:username/:type/userprofilereview"
        component={UserProfileReview}
      />
      <Route
        exact
        path="/jobs/details/:jobId/jobdetailsreviews"
        component={UserProfileReview}
      />
      <Route exact path="/LikedGig" component={LikedGigsComponent} />
      <Route exact path="/PageNotFound/:onApp?" component={PageNotFound} />
      <Route exact path="/NoInternet" component={NoInternet} />
    </div>
  </Router>
);
