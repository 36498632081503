import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SaveIcon from "@material-ui/icons/Save";
import ShareIcon from "@material-ui/icons/Share";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import loadImage from "blueimp-load-image";
import moment from "moment";
import React, { Fragment, useEffect, useState, createRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Sticky from "react-sticky-el";
import Notifications from "./Notifications";
import {
  API,
  BUTTON_TEXT,
  CLIENT_DOMAIN,
  compressedFile,
  DOMAIN,
  FORM_TYPES,
  LOCAL_STORAGE_KEY,
  PROFILE_FORM,
  PROFILE_KEYS,
  REGEX,
  REVIEW_TYPE,
  WEBVIEW_ACTION,
} from "../config/config";
import Loading from "../layout/Loading";
import { ON_FOREGROUND_STATUS } from "../store/actions/actionTypes";
import ImageTools from "../ultils/ImageTools/ImageTools.js";
import {
  openUrl,
  sendMessage,
  setAxiosToken,
  trimValue,
} from "../ultils/ultils";
import DialogComponent from "./Components/DialogComponent";
import ImageComponent from "./Components/ImageComponent";
import ReportModal from "./Components/ReportModal";
import Follows from "./FollowPages/Follows";
import Gigs from "./Gigs";
import { FOLLOW_KEYWORD } from "../config/keywords";
import FollowProfile from "./FollowProfile";
import JobBrowsingDialog from "./JobBrowsing/JobBrowsingDialog";
import JobBrowsing from "./JobBrowsing";
import GigCategoriesList from "./GigCategoriesList";
import GigAlert from "./JobBrowsing/GigAlert";
import LikedGigsComponent from "./LikedGigs/LikedGigsComponent";
import ReviewsGigs from "./Reviews/ReviewsGigs";

export default function UserProfile(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();
  const { username } = props.match.params;
  const [open, setOpen] = useState(false);
  const [openCompanyImage, setOpenCompanyImage] = useState(false);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [profile, setProfile] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const [profileEdit, setProfileEdit] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFollow, setIsFollow] = useState(false);
  const [openGigFilter, setOpenGigFilter] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState({});
  const [defaultSkills, setDefaultSkills] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [loginToken, setLoginToken] = useState(false);
  const [os, setOS] = useState(null);
  const [image, setImage] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [oldEmail, setOldEmail] = useState("");
  const [haveToken, setHaveToken] = useState(
    process.env.REACT_APP_TOKEN_STATUS === "development" ? true : false
  );
  const [onForeground, setOnForeground] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [onApp, setOnApp] = useState(null);
  const [onUpdate, setOnUpdate] = useState(false);
  const [phoneNumberErr, setPhoneNumberError] = useState(false);
  const [inValidUrl, setInValidUrl] = useState(false);
  const [refreshProfile, setRefreshProfile] = useState(false);
  const [reviewsGiggerAverageStars, setReviewsGiggerAverageStars] = useState(0);
  const [reviewsGigBossAverageStars, setReviewsGigBossAverageStars] = useState(
    0
  );
  const [errorObj, setErrorObj] = useState({});
  const [onTop, setOnTop] = useState(true);
  const [refValues, setRefValues] = useState({});
  const [alertData, setAlertData] = useState([]);
  window.onscroll = function () {
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === 0) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  };
  useEffect(() => {
    sendMessage({ action: WEBVIEW_ACTION.SET_ENABLE_REFRESH, isEnable: onTop });
  }, [onTop]);
  const getGigerInfo = async () => {
    try {
      let token = tempToken ? tempToken : localStorage[LOCAL_STORAGE_KEY.TOKEN];
      await setAxiosToken(token);
      const result = await axios.post(API.GET_GIGER_INFO, { username }, {});
      console.log({ result });
      if (username && result.data) {
        sendMessage({
          action: WEBVIEW_ACTION.UPDATE_TITLE,
          title: result.data.UserName,
        });
      }
      const {
        followers,
        followings,
        SkillIds,
        Image,
        FirstName,
        Email,
        CompanyImage,
      } = result.data;
      setImage(Image);
      setOldEmail(Email);
      setCompanyImage(CompanyImage);
      const followerList = followers.reduce((acc, cur) => {
        acc.push(cur.profileId);
        return acc;
      }, []);
      //console.log(followerList);
      const isFollower = followerList.indexOf(parseInt(currentId)) !== -1;
      setIsFollow(isFollower);
      setProfile(result.data);
    } catch (error) {
      if (error.response.status === 400) {
        if (currentId) {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound/${true}`;
        } else {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound`;
        }
      }
    }
  };

  // WEBVIEW MESSAGE
  const _onMessage = async (data) => {
    if (data.token && data.onApp) {
      //alert("PROFILE");
      //alert(data.onApp);
      setOS(data.os);
      setAxiosToken(data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      setTempToken(data.token);
      setOnApp(true);
      setLoginToken(true);
      setHaveToken(true);
    }
  };
  useEffect(() => {
    if (refreshProfile) {
      getGigerInfo();
      setRefreshProfile(false);
    }
  }, [refreshProfile]);

  const _onUpdateMessage = () => {
    setOnUpdate(true);
  };
  const _onForeGround = (data) => {
    if (!isReadOnly) {
      dispatch({ type: ON_FOREGROUND_STATUS });
      setOnForeground(true);
    } else {
      if (!username) {
        setRefreshProfile(true);
      }
    }
  };
  useEffect(() => {
    if (openGigFilter) {
      sendMessage({
        action: WEBVIEW_ACTION.DISABLE_REFRESH,
      });
    } else {
      sendMessage({
        action: WEBVIEW_ACTION.SET_ENABLE_REFRESH,
        isEnable: onTop,
      });
    }
  }, [onTop, openGigFilter]);
  const _onSetUpGigsAlert = () => {
    //alert("HERE");
    openGigAlert();
  };
  const _onCloseModalProfile = () => {
    closeNotificationModal();
    closeGigAlert();
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_profile: _onMessage,
      on_detail: _onMessage,
      on_update_profile: _onUpdateMessage,
      on_foreground: _onForeGround,
      gig_alert: _onSetUpGigsAlert,
      on_close_modal_profile: _onCloseModalProfile,
    };
  }, []);
  const getAllAlert = async () => {
    try {
      if (!username && currentId) {
        const result = await axios.post(
          API.GET_ALL_NOTIFICATION_ALERT,
          {
            UserId: currentId,
          },
          {}
        );
        setAlertData(result.data);
      }
      //testGetAlert();
    } catch (error) {
      console.log({ getAllAlertError: error });
    }
  };
  const updateCheckAlert = async (Id) => {
    try {
      const result = await axios.post(API.UPDATE_CHECK_ALERT, { Id }, {});
      setAlertData(result.data);
    } catch (error) {
      console.log({ updateCheckAlertError: error });
    }
  };
  const testGetAlert = async () => {
    try {
      const result = await axios.post(
        API.GET_ALL_NOTIFICATION_ALERT,
        {
          UserId: 1,
        },
        {}
      );
      setAlertData(result.data);
    } catch (error) {}
  };
  const testRemoveAlert = async () => {
    try {
      const result = await axios.post(
        API.REMOVE_ALL_NOTIFICATION_ALERT,
        {
          UserId: 1,
        },
        {}
      );
      setAlertData(result.data);
    } catch (error) {}
  };
  const removeAllAlert = async () => {
    try {
      if (!username && currentId) {
        const result = await axios.post(
          API.REMOVE_ALL_NOTIFICATION_ALERT,
          {
            UserId: currentId,
          },
          {}
        );
        setAlertData(result.data);
      }
      //testRemoveAlert();
    } catch (error) {
      console.log({ removeAllAlertError: error });
    }
  };

  useEffect(() => {
    if (onForeground) {
      if (haveToken || username) {
        getGigerInfo();
        // Fetch new Notification
        getAllAlert();
        setOnForeground(false);
      }
    }
  }, [onForeground, haveToken, username]);
  // Update Profile
  useEffect(() => {
    if (onUpdate) {
      updateProfile();
    }
  }, [onUpdate]);
  // Get data for reviews
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile) {
          let giggerId = profile.Id;

          const getReviewsGiggerAverageStars = await axios.post(
            API.GET_REVIEWS_AVERAGE_STARS,
            { revieweeId: giggerId, isReviewsGigger: true }
          );

          if (getReviewsGiggerAverageStars.data) {
            setReviewsGiggerAverageStars(getReviewsGiggerAverageStars.data);
          }

          const getReviewsGigBossAverageStars = await axios.post(
            API.GET_REVIEWS_AVERAGE_STARS,
            { revieweeId: giggerId, isReviewsGigger: false }
          );

          if (getReviewsGigBossAverageStars.data) {
            setReviewsGigBossAverageStars(getReviewsGigBossAverageStars.data);
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, [profile]);

  const setDefaultRef = () => {
    let firstNameRef = createRef();
    let lastNameRef = createRef();
    let instagramRef = createRef();
    let twitterRef = createRef();
    let facebookRef = createRef();
    let giggerWebsiteRef = createRef();
    let companyNameRef = createRef();
    let gigBossWebsiteRef = createRef();
    let contactNameRef = createRef();
    let addressRef = createRef();
    let phoneNumberRef = createRef();
    let emailRef = createRef();
    setRefValues({
      FirstName: firstNameRef,
      LastName: lastNameRef,
      InstagramLink: instagramRef,
      TwitterLink: twitterRef,
      FacebookLink: facebookRef,
      WebsiteLink: giggerWebsiteRef,
      CompanyName: companyNameRef,
      Website: gigBossWebsiteRef,
      ContactName: contactNameRef,
      Address: addressRef,
      PhoneNumber: phoneNumberRef,
      Email: emailRef,
    });
  };

  useEffect(() => {
    setDefaultRef();
  }, []);

  //
  const reportGigger = async () => {
    try {
      //setIsLoading(true);
      const result = await axios.post(API.INSERT_REPORT_GIGGER, {
        currentId: parseInt(currentId) ? parseInt(currentId) : null,
        username: username,
      });
      if (result.data) {
        return result.data;
      }
      //setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const followGig = async () => {
    try {
      if (currentId) {
        let token = tempToken
          ? tempToken
          : localStorage[LOCAL_STORAGE_KEY.TOKEN];
        await setAxiosToken(token);
        const result = await axios.post(API.FOLLOW_GIG, { username: username });
        setProfile(() => ({ ...profile, followers: [...result.data] }));
        sendMessage({
          action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
        });
        if (result) {
          setIsFollow(true);
        }
      } else {
        sendMessage({ action: WEBVIEW_ACTION.LOG_OUT });
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const unfollowGig = async () => {
    try {
      let token = tempToken ? tempToken : localStorage[LOCAL_STORAGE_KEY.TOKEN];
      await setAxiosToken(token);
      const result = await axios.post(API.UN_FOLLOW_GIG, {
        username: username,
      });
      setProfile(() => ({ ...profile, followers: [...result.data] }));
      sendMessage({
        action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
      });
      if (result.data) {
        setIsFollow(false);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleOpen = () => {
    if (profile.Image && isReadOnly) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCompanyImage(false);
  };
  const allowEdit = () => {
    setIsReadOnly(!isReadOnly);
    //console.log(profile);
  };
  useEffect(() => {
    sendMessage({
      action: WEBVIEW_ACTION.ON_UPDATE_PROFILE,
      isCompleted: isReadOnly,
    });
  }, [isReadOnly]);

  const updateProfile = async () => {
    let data = new FormData();
    if (profileEdit.Image) {
      data.append("Image", profileEdit.Image);
    }
    console.log({ profileEdit });
    if (profileEdit.CompanyImage) {
      data.append("CompanyImage", profileEdit.CompanyImage);
    }

    Object.keys(refValues).forEach((key) => {
      if (refValues[key].current) {
        profileEdit[key] = refValues[key].current.value;
        profile[key] = refValues[key].current.value;
      }
    });

    Object.keys(profileEdit).map((key) => {
      if (key === PROFILE_KEYS.SKILLS) {
        data.append(key, JSON.stringify(profileEdit[key]));
      } else {
        if (
          key !== "Image" &&
          key !== "CompanyImage" &&
          key !== "ConfirmPassword"
        ) {
          data.append(`${key}`, profileEdit[key]);
        }
      }
    });
    console.log({ data });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      let dataIsValid = false;
      if (Object.keys(profileEdit).length > 0) {
        dataIsValid = await validateProfileUpdate(profileEdit);
      } else {
        dataIsValid = true;
      }
      if (!dataIsValid) {
        setInValidUrl(true);
      } else {
        if (profile.PhoneNumber.length === 8) {
          setIsLoading(true);
          setInValidUrl(false);
          const result = await axios.post(API.UPDATE_PROFILE, data, config);
          setIsReadOnly(!isReadOnly);
          setErrorObj({});
          if (result.status === 200) {
            updateProfilePicture(result.data);
            let tempEmail = profileEdit.Email;
            setProfileEdit({});
            setOnUpdate(false);
            setIsLoading(false);
            setDefaultRef();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoading && profileEdit.SkillIds) {
      let newSkills = profileEdit.SkillIds.reduce((acc, cur) => {
        acc.push(cur.Name);
        return acc;
      }, []);
      setProfile({ ...profile, Skills: newSkills });
    }
  }, [isLoading]);
  const validatePassword = (passwordObj) => {
    return passwordObj.Password === passwordObj.ConfirmPassword ? true : false;
  };
  const updateProfilePicture = (profileUpdate) => {
    if (profileUpdate.Image && profileUpdate.CompanyImage) {
      setProfile({
        ...profile,
        Image: profileUpdate.Image,
        CompanyImage: profileUpdate.CompanyImage,
      });
    } else {
      if (profileUpdate.Image) {
        setProfile({ ...profile, Image: profileUpdate.Image });
      }
      if (profileUpdate.CompanyImage) {
        setProfile({
          ...profile,
          CompanyImage: profileUpdate.CompanyImage,
        });
      }
    }
  };
  const checkValidateUrlData = (url) => {
    let urlIsValid = true;
    if (url && url.length > 0) {
      if (url.includes(FORM_TYPES.WHITE_SPACE)) {
        urlIsValid = false;
      } else {
        urlIsValid = validateUrl(url);
      }
    }
    return urlIsValid;
  };

  const checkValidateEmail = async (Email) => {
    try {
      let emailValidObj = { isValid: true };
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = await regex.test(Email);

      if (isValidEmail) {
        // check exist
        const result = await axios.post(API.CHECK_EMAIL_EXISTS, { Email }, {});
        if (result.data.isExist) {
          emailValidObj.isValid = false;
          emailValidObj.message =
            PROFILE_FORM.ERROR_MESSAGE.EMAIL_IS_ALREADY_EXIST;
        }
      } else {
        emailValidObj.isValid = false;
        emailValidObj.message = PROFILE_FORM.ERROR_MESSAGE.EMAIL_IS_INVALID;
      }
      return emailValidObj;
    } catch (error) {
      console.log({ error });
    }
  };
  const validateProfileUpdate = async (profileUpdate) => {
    let dataIsValid = true;
    let error = {};

    if (profileUpdate.Email && profileUpdate.Email !== oldEmail) {
      let emailValidObj = await checkValidateEmail(profileUpdate.Email);
      dataIsValid = emailValidObj.isValid;
      console.log({ dataIsValid });
      error.invalidEmail = dataIsValid === false;
      error.message = emailValidObj.message;
    }
    if (profileUpdate.Website) {
      dataIsValid = checkValidateUrlData(profileUpdate.Website);
      error.invalidUrl = dataIsValid === false;
    }
    if (profileUpdate.WebsiteLink) {
      dataIsValid = checkValidateUrlData(profileUpdate.WebsiteLink);
      error.invalidUrl = dataIsValid === false;
    }
    if (profileUpdate.Password || profileUpdate.ConfirmPassword) {
      dataIsValid = validatePassword(profileUpdate);
      error.invalidPassword = dataIsValid === false;
    }
    setErrorObj(error);
    return dataIsValid;
  };
  const handleChange = async (e, type, name) => {
    const imageTool = new ImageTools();
    if (e.target.name === FORM_TYPES.DOB) {
      setProfile({
        ...profile,
        [e.target.name]: e.target.value,
      });
    } else {
      setProfile({
        ...profile,
        [e.target.name]:
          type == FORM_TYPES.FILE ? e.target.files[0] : e.target.value,
      });
    }
    setProfileEdit({
      ...profileEdit,
      [e.target.name]:
        type == FORM_TYPES.FILE
          ? e.target.files[0]
          : type == FORM_TYPES.PASSWORD
          ? trimValue(e.target.value)
          : e.target.value,
    });
    if (e.target.files) {
      loadImage(e.target.files[0], {
        maxWidth: compressedFile.maxWidthOrHeight,
        maxHeight: compressedFile.maxWidthOrHeight,
        orientation: true,
        canvas: true,
      })
        .then(async (data) => {
          const image = imageTool.canvastoBlob(data.image, "image/png");
          setPreviewPhoto({ ...previewPhoto, [name]: image.data });
          setProfile({
            ...profile,
            [name]: image.blob,
          });
          setProfileEdit({
            ...profileEdit,
            [name]: image.blob,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSelected = (type, value) => {
    setProfile({ ...profile, [type]: value });
    setProfileEdit({ ...profileEdit, [type]: value });
  };
  const logOut = async () => {
    const newDarkState = !darkState;
    localStorage.removeItem("token");
    localStorage.removeItem(LOCAL_STORAGE_KEY.DARK_MODE);
    setTimeout(() => {
      sendMessage({
        action: WEBVIEW_ACTION.LOG_OUT,
        darkMode: newDarkState,
      });
    }, 200);
  };
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        "& > *": {
          margin: theme.spacing(1),
        },
      },
      medium: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginBottom: 7,
      },
    })
  );
  const classes = useStyles();
  const [value, setValue] = React.useState(2);

  // Dark Mode
  const [darkState, setDarkState] = useState(
    localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.DARK_MODE])
      : false
  );
  const palletType = darkState ? "dark" : "light";
  // set default Dark Mode
  useEffect(() => {
    //console.log("DARK STATE RUNNN");
    if (darkState !== null) {
      darkState
        ? document.body.classList.add("darkmode")
        : document.body.classList.remove("darkmode");
    }
  }, [darkState]);
  const darkTheme = createMuiTheme({
    palette: {
      type: darkState ? "dark" : "light",
      background: {
        default: darkState ? "#121212" : "#f5f5f5",
      },
      primary: {
        main: "#832CFF",
      },
      secondary: {
        main: "#f44336",
      },
    },
    typography: {
      fontFamily: "Montserrat, Arial",
      fontWeightRegular: 500,
      fontSize: 13.5,
    },
  });
  const handleThemeChange = () => {
    const newDarkState = !darkState;
    setDarkState(newDarkState);
    localStorage.setItem(LOCAL_STORAGE_KEY.DARK_MODE, newDarkState);
    sendMessage({
      action: WEBVIEW_ACTION.TOGGLE_DARK_MODE,
      darkMode: newDarkState,
    });
  };
  const shareProfile = () => {
    let profileUrl = username
      ? window.location.href
      : `${window.location.href}/${profile.UserName}`;
    sendMessage({
      action: WEBVIEW_ACTION.ON_SHARE,
      url: profileUrl,
    });
  };

  const handleReviewsGiggerClick = () => {
    let profileName = username || profile.UserName;
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_RATING_PAGE,
      url: `${CLIENT_DOMAIN}user/${profileName}/${REVIEW_TYPE.GIGER}/userprofilereview`,
      title: "Reviews for Gigger",
    });
  };

  const handleReviewsGigBossClick = () => {
    let profileName = username || profile.UserName;
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_RATING_PAGE,
      url: `${CLIENT_DOMAIN}user/${profileName}/${REVIEW_TYPE.GIGBOSS}/userprofilereview`,
      title: "Reviews for Gig Boss",
    });
  };
  const handleCloseEditModal = () => {
    setOpenState(false);
  };
  const handleOpenEditModal = () => {
    if (!username) {
      setOpenState(true);
    }
  };
  const handleClickUrl = () => {
    console.log(profile.Website);
    if (profile.Website) {
      openUrl(profile.Website, onApp);
    }
  };
  const handleClickSocial = (socialLink) => {
    if (socialLink) {
      openUrl(socialLink, onApp);
    }
  };
  const validateUrl = (websiteLink) => {
    let urlRegex = new RegExp(REGEX.URL);
    const isValidUrl = urlRegex.test(websiteLink);
    return isValidUrl;
  };
  const handleOpenCompanyImage = () => {
    if (profile.CompanyImage && isReadOnly) {
      setOpenCompanyImage(true);
    }
  };
  const handleOpenUrl = ({ type }) => {
    console.log({ type });
    if (onApp) {
      let paramUrl = type;
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
        url: `${CLIENT_DOMAIN}${paramUrl}`,
        open: true,
      });
    } else {
      // let paramUrl = type;
      // let url = `${CLIENT_DOMAIN}${paramUrl}`;
      // console.log({ url });
    }
  };
  const toggleModal = () => {
    setShowReportModal(!showReportModal);
    // && !onForeGround
  };
  const openGigAlert = () => {
    setOpenGigFilter(true);
  };
  const closeGigAlert = () => {
    setOpenGigFilter(false);
  };
  const openNotificationModal = () => {
    sendMessage({ action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE });
    setOpenNotification(true);
  };
  const closeNotificationModal = () => {
    sendMessage({
      action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE,
      closeModal: true,
    });
    setOpenNotification(false);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      {Object.keys(profile).length > 0 ? (
        <div style={{ minHeight: "100vh" }}>
          <Sticky>
            <div
              className={
                isReadOnly
                  ? "boxUserProfile"
                  : "boxUserProfile boxUserProfileonEdit"
              }
            >
              <div className="buttonEdit">
                {onApp && username ? (
                  <Fragment>
                    {!isFollow ? (
                      <div className="followbtn">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={followGig}
                        >
                          {BUTTON_TEXT.FOLLOW}
                        </Button>
                      </div>
                    ) : (
                      <div className="followbtn">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={unfollowGig}
                        >
                          {BUTTON_TEXT.UNFOLLOW}
                        </Button>
                      </div>
                    )}
                  </Fragment>
                ) : haveToken && !username ? (
                  <IconButton
                    onClick={isReadOnly ? allowEdit : updateProfile}
                    variant="inherit"
                    style={{ color: "#fff" }}
                  >
                    {isReadOnly ? <EditRoundedIcon /> : <SaveIcon />}
                  </IconButton>
                ) : (
                  <div className="followbtn"></div>
                )}
                {/* {!username ? (
                  <IconButton
                    onClick={isReadOnly ? allowEdit : updateProfile}
                    variant="inherit"
                    style={{ color: "#fff" }}
                  >
                    {isReadOnly ? <EditRoundedIcon /> : <SaveIcon />}
                  </IconButton>
                ) : null} */}

                {username ? null : (
                  <span>
                    <Notifications
                      os={os}
                      data={alertData}
                      open={openNotification}
                      openModal={openNotificationModal}
                      closeModal={closeNotificationModal}
                      removeAlert={removeAllAlert}
                      updateAlert={updateCheckAlert}
                    />
                    <IconButton
                      style={{ color: "#fff" }}
                      onClick={shareProfile}
                    >
                      <ShareIcon />
                    </IconButton>
                  </span>
                )}
              </div>

              <div className="avatarUser">
                {isReadOnly ? (
                  <Avatar
                    onClick={handleOpen}
                    alt={profile.username}
                    src={
                      previewPhoto["Image"]
                        ? previewPhoto["Image"]
                        : profile.Image
                        ? `${DOMAIN}profile/${profile.Image}`
                        : "/static/images/avatar/1.jpg"
                    }
                    className="avatar"
                  />
                ) : (
                  <Fragment>
                    <Avatar
                      alt={profile.username}
                      src={
                        previewPhoto["Image"]
                          ? previewPhoto["Image"]
                          : profile.Image
                          ? `${DOMAIN}profile/${profile.Image}`
                          : "/static/images/avatar/1.jpg"
                      }
                      className="avatar"
                    />
                    <input
                      type="file"
                      className="file"
                      name="Image"
                      accept="image/*"
                      onChange={(e) =>
                        handleChange(e, FORM_TYPES.FILE, "Image")
                      }
                    />
                  </Fragment>
                )}
                <div className="name" style={{ textAlign: "center" }}>
                  <h5>{`${profile.UserName ? profile.UserName : ""}`}</h5>
                  {/* <Typography
                  variant="subtitle1"
                  style={{ color: "#999", marginBottom: 10, fontSize: 14 }}
                >
                  {`${profile.Skills}`}
                </Typography> */}
                </div>
              </div>
              <div className="profile-widgets">
                <div>
                  <h6
                    className="text-muted"
                    onClick={
                      reviewsGiggerAverageStars > 0
                        ? handleReviewsGiggerClick
                        : null
                    }
                  >
                    Reviews for Gigger{" "}
                    <span>
                      {" "}
                      {`(${
                        reviewsGiggerAverageStars > 0
                          ? reviewsGiggerAverageStars
                          : 0
                      } / 5)`}{" "}
                    </span>
                  </h6>
                  <Rating
                    name="half-rating-read"
                    value={
                      reviewsGiggerAverageStars > 0
                        ? reviewsGiggerAverageStars
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </div>
                <div>
                  <h6
                    className="text-muted"
                    onClick={
                      reviewsGigBossAverageStars > 0
                        ? handleReviewsGigBossClick
                        : null
                    }
                  >
                    Reviews for Gig Boss{" "}
                    <span>
                      {" "}
                      {`${
                        reviewsGigBossAverageStars > 0
                          ? reviewsGigBossAverageStars
                          : 0
                      } / 5`}{" "}
                    </span>
                  </h6>
                  <Rating
                    name="half-rating-read"
                    value={
                      reviewsGigBossAverageStars > 0
                        ? reviewsGigBossAverageStars
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </Sticky>
          {/* {console.log(profile.followers.length)} */}
          {profile.followers && profile.followings ? (
            <Follows
              followers={profile.followers}
              following={profile.followings}
              username={username}
              followerTitle={FOLLOW_KEYWORD.FOLLOWERS}
              followingTitle={FOLLOW_KEYWORD.FOLLOWING}
            />
          ) : null}
          {/* {profile.followers && profile.followers.length > 0 ? (
            <FollowProfile
              follows={profile.followers}
              username={username}
              title="Followers"
            />
          ) : null}
          {profile.followings && profile.followings.length > 0 && !username ? (
            <FollowProfile
              follows={profile.followings}
              username={username}
              title="Following"
            />
          ) : null} */}
          <Gigs
            os={os}
            profile={profile}
            profileEdit={profileEdit}
            selectedSkills={profile.SkillIds}
            handleSelected={handleSelected}
            isReadOnly={isReadOnly}
            companyImage={companyImage}
            previewPhoto={previewPhoto}
            username={username}
            inValidUrl={inValidUrl}
            errorObj={errorObj}
            handleClickSocial={handleClickSocial}
            handleChange={handleChange}
            handleOpenEditModal={handleOpenEditModal}
            handleClickUrl={handleClickUrl}
            handleOpen={handleOpenCompanyImage}
            refValues={refValues}
          />
          {profile.Image && isReadOnly && (
            <ImageComponent
              avatar={`${DOMAIN}profile/${profile.Image}`}
              open={open}
              handleClose={handleClose}
            />
          )}
          {profile.CompanyImage && isReadOnly && (
            <ImageComponent
              avatar={`${DOMAIN}profile/${profile.CompanyImage}`}
              open={openCompanyImage}
              handleClose={handleClose}
            />
          )}

          {username ? null : (
            <Fragment>
              <div className="links">
                <ListItem button style={{ paddingRight: 7 }}>
                  Dark Mode{" "}
                  <Switch
                    color="primary"
                    checked={darkState}
                    onChange={handleThemeChange}
                  />
                </ListItem>
                <ListItem className="GigAlert" onClick={() => openGigAlert()}>
                  Gig Alert
                  <ArrowForwardIosIcon
                    style={{ color: "#832cff", fontSize: 15 }}
                  />
                </ListItem>
                {!username && currentId && (
                  <ListItem
                    className="GigAlert"
                    onClick={() => handleOpenUrl({ type: "LikedGig" })}
                  >
                    Liked Gigs
                    <ArrowForwardIosIcon
                      style={{ color: "#832cff", fontSize: 15 }}
                    />
                  </ListItem>
                )}
                {/* <JobBrowsingDialog
                  openDialog={openGigFilter}
                  handleDialogClose={closeGigAlert}
                  // onFilterJobsApply={handleFilterJobsApply}
                  // existingFilterData={filterData}
                /> */}
                {/* <JobBrowsing fromUserProfile={true} /> */}

                {/* {[1, 2].length > 0 && (
                  <ReviewsGigs
                    data={[
                      {
                        GigerId: 1,
                        Title: "Travel Sales Ambassador",
                        GigBossId: 16,
                        completedId: 103,
                        Image: "photo-1598608713318.jpeg",
                        CompanyImage: null,
                        jobId: 231,
                      },
                      {
                        GigerId: 1,
                        Title: "?TEMP SCREENER?",
                        GigBossId: 282,
                        completedId: 102,
                        Image: "photo-1602654629472.jpeg",
                        CompanyImage: null,
                        jobId: 210,
                      },
                    ]}
                  />
                )} */}
                <ListItem onClick={() => handleOpenUrl({ type: "About" })}>
                  About gigsomething
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                </ListItem>
                <ListItem onClick={() => handleOpenUrl({ type: "Community" })}>
                  Community Standards
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                  {/* <CommunityStandards /> */}
                </ListItem>
                <ListItem onClick={() => handleOpenUrl({ type: "Faq" })}>
                  FAQ
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                  {/* <FAQ /> */}
                </ListItem>
                <GigAlert
                  fromProfile={true}
                  onForeground={onForeground}
                  openState={openGigFilter}
                  handleClose={closeGigAlert}
                />

                <ListItem
                  onClick={() => handleOpenUrl({ type: "ChangePassword" })}
                >
                  Change Password
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                </ListItem>
              </div>
              {isReadOnly ? (
                <div className="logOut">
                  <Button onClick={logOut} color="secondary">
                    {BUTTON_TEXT.LOG_OUT}
                  </Button>
                </div>
              ) : null}
            </Fragment>
          )}
          <DialogComponent
            openState={openState}
            showEdit={true}
            handleClose={handleCloseEditModal}
          />
          {onApp && username && currentId && (
            <div style={{ margin: "0 16px" }}>
              <Link
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#832cff",
                }}
                onClick={toggleModal}
              >
                {BUTTON_TEXT.REPORT}
              </Link>
            </div>
          )}
          {showReportModal ? (
            <ReportModal
              openDialog={showReportModal}
              toggleModal={toggleModal}
              reportGigger={reportGigger}
            />
          ) : null}
        </div>
      ) : (
        <Fragment>
          <Loading />
        </Fragment>
      )}
      {isLoading ? <Loading /> : null}
    </ThemeProvider>
  );
}
