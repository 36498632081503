import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import NotificationsIcon from "@material-ui/icons/Notifications";
import WorkIcon from "@material-ui/icons/Work";
import { NOTIFICATION_TYPES } from "../config/notificationTypes";
import { sendMessage } from "../ultils/ultils";
import {
  CLIENT_DOMAIN,
  FORMAT,
  GIG_LINKS,
  WEBVIEW_ACTION,
} from "../config/config";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mockData = [
  {
    Id: 1616,
    Code: "3sobzdj70",
    FollowerId: null,
    Type: "LIKED_GIG",
    Message: "Tuan Luong has applied for Travel Sales Ambassador.",
    UserName: null,
    UnCheck: 1,
    IsCheck: 0,
  },
  {
    Id: 1617,
    Code: "3sobzdj70",
    FollowerId: null,
    Type: "LIKED_GIG",
    Message: "Tuan Luong has applied for Travel Sales Ambassador.",
    UserName: null,
    UnCheck: 1,
    IsCheck: 0,
  },
  {
    Id: 1618,
    Code: "3sobzdj70",
    FollowerId: null,
    Type: "LIKED_GIG",
    Message: "Tuan Luong has applied for Travel Sales Ambassador.",
    UserName: null,
    UnCheck: 1,
    IsCheck: 0,
  },
  {
    Id: 1621,
    Code: null,
    FollowerId: 3,
    Type: "LIKED_GIG",
    Message: "Your gig have received total 1 like.",
    UserName: "thanh",
    UnCheck: 1,
    IsCheck: 0,
  },
];

export default function FullScreenDialog({
  data,
  removeAlert,
  updateAlert,
  os,
  open,
  openModal,
  closeModal,
}) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [testData, setTestData] = useState([]);
  const handleClickOpen = async () => {
    // setOpen(true)
    openModal();
    if (removeAlert) {
      await removeAlert();
    }
    //setTestData([]);
  };
  const getAlertBadgesCount = (obj = []) => {
    return obj.reduce((acc, cur) => {
      let initialValue = acc || 0;
      let value = cur.UnCheck || 0;
      return initialValue + value;
    }, 0);
    //return 0;
  };
  const handleClose = () => {
    //setOpen(false);
    closeModal();
  };
  const handleItemClick = async ({ type, Code, FollowerId, Id, Username }) => {
    try {
      console.log({ type, Code, FollowerId, Id });
      await updateAlert(Id);
      if (type === NOTIFICATION_TYPES.FOLLOW_ACTION) {
        sendMessage({
          action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
          profileName: Username,
        });
      }
      if (
        type === NOTIFICATION_TYPES.APPLIED_GIG ||
        type === NOTIFICATION_TYPES.LIKED_GIG ||
        type === NOTIFICATION_TYPES.POST_GIG ||
        type === NOTIFICATION_TYPES.REJECT_GIG ||
        type === NOTIFICATION_TYPES.CONFIRMED_GIG ||
        type === NOTIFICATION_TYPES.GIG_ALERT_FILTER
      ) {
        sendMessage({
          action: WEBVIEW_ACTION.JOB_DETAIL,
          url: `${CLIENT_DOMAIN}${GIG_LINKS.GIG_DETAILS}/${Code}`,
          //url: "http://192.168.0.100:3000/Gigs/Details/gq86zbaaz",
          jobTitle: "",
          canShare: true,
        });
      }

      //handleClose();
    } catch (error) {}
  };
  return (
    <>
      <IconButton
        style={{ color: "#fff" }}
        className="notifications"
        onClick={handleClickOpen}
      >
        {getAlertBadgesCount(data) > 0 && (
          <span className="number">{getAlertBadgesCount(data)}</span>
        )}
        {/* <span className="number">4</span> */}
        <NotificationsIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ paddingBottom: os === "android" ? 55 : 0 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton style={{ visibility: "hidden" }}>
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ textAlign: "center", marginLeft: 0 }}
            >
              Notifications
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List className="bgList">
          {data.map((item, i) => (
            <Fragment key={i}>
              <ListItem
                onClick={() =>
                  handleItemClick({
                    type: item.Type,
                    Code: item.Code,
                    FollowerId: item.FollowerId,
                    Username: item.UserName,
                    Id: item.Id,
                  })
                }
                button
                className={`${item.IsCheck ? "bgListBtn" : "bgListactive"}`}
              >
                <NotificationsIcon
                  fontSize="small"
                  style={{ marginRight: 15 }}
                />
                <ListItemText
                  primary={item.Message}
                  secondary={`${moment(item.CreatedDate).format(
                    FORMAT.DATE_TIME_CLIENT
                  )} ${moment(item.CreatedDate).format("HH:mm")}`}
                />
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Dialog>
    </>
  );
}
