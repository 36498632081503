import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { API, REVIEW_RATING_PAGE_SIZE, REVIEW_TYPE } from "../../config/config";
import DarkMode from "../DarkMode/DarkMode";
import ReviewRatingCommentItem from "../Reviews/ReviewRatingCommentItem";
import ReviewRatingPercent from "../Reviews/ReviewRatingPercent";
import ReviewsFilter from "../Reviews/ReviewsFilter";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  chipFilter: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    },

    marginTop: 14
  }
}));

const UserProfileReview = (props) => {
  const classes = useStyles();
  const [darkState, setDarkState] = useState(null);
  const { username, type, jobId } = props.match.params;
  const [reviews, setReviews] = useState(null);
  const [profile, setProfile] = useState(null);
  const [reviewersInformation, setReviewersInformation] = useState([]);
  const [reviewsToRender, setReviewsToRender] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasIsReviewsGiggerClick, setHasIsReviewsGiggerClick] = useState(false);
  const [sortBy, setSortBy] = useState("");

  //#region useEffect

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (username) {
          const userProfile = await axios.post(API.GET_GIGER_INFO, {
            username
          });
          if (userProfile.data) {
            setProfile(userProfile.data);

            let giggerId = userProfile.data["Id"];
            let isReviewsGiggerClick = type === REVIEW_TYPE.GIGER;
            setHasIsReviewsGiggerClick(isReviewsGiggerClick);
            let reviewsForCurrentUser = null;
            const getAllReviewsForCurrentUser = await axios.post(
              API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
              {
                revieweeId: giggerId,
                isReviewsGiggerClick: isReviewsGiggerClick,
                pageSize: REVIEW_RATING_PAGE_SIZE,
                startIndex: 0
              }
            );

            if (getAllReviewsForCurrentUser.data) {
              setReviews(getAllReviewsForCurrentUser.data);
              setReviewsToRender(
                getAllReviewsForCurrentUser.data.ReviewRatings
              );
              reviewsForCurrentUser =
                getAllReviewsForCurrentUser.data.ReviewRatings;
            }
            if (reviewsForCurrentUser && reviewsForCurrentUser.length > 0) {
              let reviewerIdsArr = [];
              reviewsForCurrentUser.map((review) => {
                reviewerIdsArr.push(review.ReviewerId);
              });

              const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
                giggerIds: reviewerIdsArr
              });
              setReviewersInformation(getGiggersByIds.data);
            }
          }
        }

        if (jobId) {
          let reviewsForCurrentJob = null;
          const getAllReviewRatingsByJobId = await axios.post(
            API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID,
            {
              jobId: jobId,
              pageSize: REVIEW_RATING_PAGE_SIZE,
              startIndex: 0,
              isGigBossReviewGigger: false
            }
          );

          if (getAllReviewRatingsByJobId.data) {
            setReviews(getAllReviewRatingsByJobId.data);
            setReviewsToRender(getAllReviewRatingsByJobId.data.ReviewRatings);
            reviewsForCurrentJob =
              getAllReviewRatingsByJobId.data.ReviewRatings;
          }

          if (reviewsForCurrentJob && reviewsForCurrentJob.length > 0) {
            let reviewerIdsArr = [];
            reviewsForCurrentJob.map((review) => {
              reviewerIdsArr.push(review.ReviewerId);
            });

            const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
              giggerIds: reviewerIdsArr
            });
            setReviewersInformation(getGiggersByIds.data);
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {}, [reviewsToRender]);

  useEffect(() => {
    const fetchData = async () => {
      if (sortBy !== "") {
        let reviewsForCurrentJob = null;
        const getAllReviewRatingsByJobId = await axios.post(
          API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID,
          {
            jobId: jobId,
            pageSize: REVIEW_RATING_PAGE_SIZE,
            startIndex: 0,
            sortBy: sortBy,
            isGigBossReviewGigger: false
          }
        );

        if (getAllReviewRatingsByJobId.data) {
          setReviews(getAllReviewRatingsByJobId.data);
          setReviewsToRender(getAllReviewRatingsByJobId.data.ReviewRatings);
          reviewsForCurrentJob = getAllReviewRatingsByJobId.data.ReviewRatings;
        }

        if (reviewsForCurrentJob && reviewsForCurrentJob.length > 0) {
          let reviewerIdsArr = [];
          reviewsForCurrentJob.map((review) => {
            reviewerIdsArr.push(review.ReviewerId);
          });

          const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
            giggerIds: reviewerIdsArr
          });
          setReviewersInformation(getGiggersByIds.data);
        }
      }
    };

    fetchData();
  }, [sortBy]);

  //#endregion

  //#region onClick

  const handleClose = () => {
    if (username) {
      props.history.push({
        pathname: `/user/${username}`
      });
    }

    if (jobId) {
      props.history.push({
        pathname: `/jobs/details/${jobId}`
      });
    }
  };

  const handleFilterClick = (filterValue) => {
    setSortBy(filterValue);
  };

  //#endregion

  //#region Infinite scroll

  const fetchMoreData = () => {
    const getMoreData = async () => {
      if (profile) {
        const getAllReviewsForCurrentUser = await axios.post(
          API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
          {
            revieweeId: profile.Id,
            isReviewsGiggerClick: hasIsReviewsGiggerClick,
            pageSize: REVIEW_RATING_PAGE_SIZE,
            startIndex: reviewsToRender.length
          }
        );

        if (getAllReviewsForCurrentUser.data) {
          setReviewsToRender(
            reviewsToRender.concat(
              getAllReviewsForCurrentUser.data.ReviewRatings
            )
          );

          let reviewerIdsArr = [];
          getAllReviewsForCurrentUser.data.ReviewRatings.map((review) => {
            reviewerIdsArr.push(review.ReviewerId);
          });

          const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
            giggerIds: reviewerIdsArr
          });
          setReviewersInformation(
            reviewersInformation.concat(getGiggersByIds.data)
          );

          if (
            getAllReviewsForCurrentUser.data.ReviewRatings.length <
            REVIEW_RATING_PAGE_SIZE
          ) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      }

      if (jobId) {
        const getAllReviewRatingsByJobId = await axios.post(
          API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID,
          {
            jobId: jobId,
            pageSize: REVIEW_RATING_PAGE_SIZE,
            startIndex: reviewsToRender.length,
            isGigBossReviewGigger: false
          }
        );

        if (getAllReviewRatingsByJobId.data) {
          setReviewsToRender(
            reviewsToRender.concat(
              getAllReviewRatingsByJobId.data.ReviewRatings
            )
          );

          let reviewerIdsArr = [];
          getAllReviewRatingsByJobId.data.ReviewRatings.map((review) => {
            reviewerIdsArr.push(review.ReviewerId);
          });

          const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
            giggerIds: reviewerIdsArr
          });
          setReviewersInformation(
            reviewersInformation.concat(getGiggersByIds.data)
          );

          if (
            getAllReviewRatingsByJobId.data.ReviewRatings.length <
            REVIEW_RATING_PAGE_SIZE
          ) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      }
    };

    getMoreData();
  };

  //#endregion

  return (
    <DarkMode darkState={darkState}>
      <Fragment>
        {reviewersInformation.length > 0 && reviews ? (
          <>
            <div className="reviews" style={{marginTop:'0',marginBottom:'-60px'}}>
              <ReviewRatingPercent
                currentReviewRatingsPercent={reviews.ReviewRatingsPercent}
                currentTotalReviewRatings={reviews.TotalReviewRatings}
              />
              {jobId ? (
                <ReviewsFilter handleFilterClick={handleFilterClick} />
              ) : null}
              <ul className="comment">
                <InfiniteScroll
                  dataLength={reviewsToRender.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <div style={{ textAlign: "center" }}>
                      <div class="loader"></div>
                    </div>
                  }
                >
                  {reviewsToRender.map((review) => {
                    let reviewerInformation = reviewersInformation.find(
                      (reviewer) => reviewer.Id === review.ReviewerId
                    );
                    return (
                      <ReviewRatingCommentItem
                        reviewRating={review}
                        reviewerInformation={reviewerInformation}
                      />
                    );
                  })}
                </InfiniteScroll>
              </ul>
            </div>
          </>
        ) : null}
      </Fragment>
    </DarkMode>
  );
};

export default UserProfileReview;
