import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  CLIENT_DOMAIN,
  LOCAL_STORAGE_KEY,
  WEBVIEW_ACTION
} from "../config/config";
import { getAxios } from "../ultils/helper";
import { sendMessage } from "../ultils/ultils";
import NotificationAlert from "./Alerts/NotificationAlert";
import SliderBanner from "./Banner/SliderBanner";
import UserBlockedDialog from "./Components/UserBlockedDialog";
import WelcomeDialog from "./Components/WelcomeDialog";
import DarkMode from "./DarkMode/DarkMode";
import CompletedGigs from "./DashboardItem/CompletedGigs";
import GigCategories from "./DashboardItem/GigCategories";
import GigForYou from "./DashboardItem/GigForYou";
import UpComingGigs from "./DashboardItem/UpComingGigs";
import ReviewsGigs from "./Reviews/ReviewsGigs";
import { GATracking } from "./Tracking/GATracking";

export default function Dashboard() {
  const [darkState, setDarkState] = useState(null);
  const [banners, setBanners] = useState([]);
  const [haveToken, setHaveToken] = useState(
    process.env.REACT_APP_TOKEN_STATUS === "development1" ? true : false
  );

  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [isAllow, setIsAllow] = useState(true);
  const [alertNotification, setAlertNotification] = useState(false);
  const [onForeGround, setOnForeground] = useState(true);
  const [openUserBlocked, setOpenUserBlocked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [haveLikedGig, setHaveLikedGig] = useState(false);
  const [gigRatedList, setGigRatedList] = useState([]);
  const [onTop, setOnTop] = useState(true);
  const [onMessage, setOnMessage] = useState(false);
  window.onscroll = function () {
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === 0) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  };
  const _onToggle = async (data) => {
    setDarkState(data.darkMode);
  };
  const _onMessage = async (data) => {
    if (data.token) {
      //alert("ON_DASHBOARD");
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_ID, data.currentId);
      setHaveToken(true);
    }
  };
  const [areas, setAreas] = useState([]);
  const getBanners = async () => {
    const data = await getAxios(API.GET_ALL_SLIDERS);
    if (data.length > 0) {
      setBanners(data);
    } else {
      setBanners([]);
    }
  };
  // useEffect(() => {
  //   if (onForeGround) {
  //     getBanners();
  //   }
  // }, [onForeGround]);
  useEffect(() => {
    const fetchData = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      setAreas(getAllAreas.data);
    };
    const checkGigAlert = async (Id) => {
      try {
        const result = await axios.post(API.GET_WELCOME_STATUS, { Id });

        if (!result.data.Active) {
          sendMessage({ action: WEBVIEW_ACTION.DISABLED_USER });
        } else {
        }
        if (result.data.showGigAlert && !result.data.IsDismiss) {
          setAlertNotification(true);
        } else {
          setAlertNotification(false);
        }
      } catch (error) {}
    };
    const getAllUnRatedGigs = async (Id) => {
      try {
        const result = await axios.post(API.GET_ALL_UNRATED_GIGS, {
          UserId: Id,
        });
        if (result.data && result.data.length > 0) {
          console.log({ data: result.data });
          setGigRatedList(result.data);
          sendMessage({ action: WEBVIEW_ACTION.ON_REVIEW_GIG });
        }
      } catch (error) {
        console.log({ getAllUnRatedGigsError: error });
      }
    };

    if (onForeGround) {
      //alert("onForeGround");
      const Id = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];

      fetchData();
      getBanners();
      setOnForeground(false);
      if (Id) {
        checkGigAlert(Id);
        getAllUnRatedGigs(Id);
        checkLikedGigExist();
      }
    }
  }, [onForeGround]);

  useEffect(() => {
    const getUserData = async (Id) => {
      try {
        const result = await axios.post(API.GET_WELCOME_STATUS, { Id });
        //console.log({ result: result.data });
        if (!result.data.IsWelcome) {
          setOpenDialog(true);
        }
      } catch (error) {}
    };
    if (haveToken) {
      const Id = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
      if (Id) {
        getUserData(Id);
      }
    }
  }, [haveToken]);
  const _onForeGround = async () => {
    setOnForeground(true);
  };
  const _onCheckingConnection = () => {
    sendMessage({
      action: WEBVIEW_ACTION.CHECK_CONNECTION,
      connected: true,
    });
  };
  const checkLikedGigExist = async () => {
    try {
      if (currentId) {
        const result = await axios.post(API.GET_ALL_LIKED_GIG, {
          UserId: currentId,
          limit: 1,
          offset: 0,
        });
        if (result.data.length > 0) {
          setHaveLikedGig(true);
        } else {
          setHaveLikedGig(false);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (onMessage) {
      checkLikedGigExist();
      setOnMessage(false);
    }
  }, [onMessage]);
  const _onFetchLikedGig = () => {
    setOnMessage(true);
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_dashboard: _onMessage,
      toggle_darkmode: _onToggle,
      on_foreground: _onForeGround,
      check_connection: _onCheckingConnection,
      fetch_liked_gig: _onFetchLikedGig,
    };
  }, []);
  const handleClose = async () => {
    await updateWelcomeStatus();
  };
  const handleUserBlocked = () => {
    setOpenUserBlocked(false);
    sendMessage({ action: WEBVIEW_ACTION.LOG_OUT });
  };
  const updateWelcomeStatus = async () => {
    try {
      const Id = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
      const result = await axios.post(API.UPDATE_WELCOME_STATUS, { Id });
      if (result.data.isSuccess) {
        setOpenDialog(false);
      }
    } catch (error) {}
  };
  const handleDismiss = async () => {
    try {
      const Id = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
      if (Id) {
        const result = await axios.post(API.UPDATE_DISMISS_STATUS, { Id });
        if (result.data.isSuccess) {
          setAlertNotification(false);
        }
      }
    } catch (error) {}
  };
  const handleOpenLikedGig = (paramUrl) => {
    const url = `${CLIENT_DOMAIN}${paramUrl}`;
    if (isAllow) {
      setIsAllow(false);
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
        url: `${CLIENT_DOMAIN}${paramUrl}`,
        open: true,
      });
      setTimeout(() => {
        setIsAllow(true);
      }, 300);
    }
  };
  useEffect(() => {
    sendMessage({ action: WEBVIEW_ACTION.SET_ENABLE_REFRESH, isEnable: onTop });
  }, [onTop]);

  return (
    <DarkMode darkState={darkState}>
      {/* <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-RZ3060GL2H"
        ></script>
        <script>{initGA()}</script>
      </Helmet> */}
      <GATracking />
      <div className="dashboard">
        <div
          style={{
            margin: "11px 16px 0 16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={require("../images/logo.svg")} />
          <div className="iconFavourite">
            <IconButton
              className="notifications"
              onClick={() => handleOpenLikedGig("LikedGig")}
            >
              {haveLikedGig ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          </div>
        </div>
        {/* {process.env.REACT_APP_NODE_ENV === "development" && <h1>TEST</h1>} */}
        {banners.length > 0 && <SliderBanner banners={banners} />}
        {/* <SliderBanner /> */}
        {alertNotification && (
          <NotificationAlert handleDismiss={handleDismiss} />
        )}
        <GigCategories onForeGround={onForeGround} />
        {areas.length > 0 && (
          <Fragment>
            {!onForeGround && haveToken && (
              <>
                <UpComingGigs areas={areas} />
                <CompletedGigs areas={areas} />
              </>
            )}
            {!onForeGround && <GigForYou areas={areas} />}
            {/* <GigForYou onForeGround={onForeGround} areas={areas} /> */}
          </Fragment>
        )}
      </div>
      {gigRatedList.length > 0 && <ReviewsGigs data={gigRatedList} />}
      <WelcomeDialog openDialog={openDialog} handleClick={handleClose} />
      <UserBlockedDialog
        openDialog={openUserBlocked}
        handleClick={handleUserBlocked}
      />
    </DarkMode>
  );
}
