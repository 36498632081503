import {
  Chip,
  Container,
  IconButton,
  List,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Sticky from "react-sticky-el";
import {
  API,
  FORMAT,
  JOB_LIST_PAGE_SIZE,
  LOCAL_STORAGE_KEY,
} from "../config/config";
import Loading from "../layout/Loading";
import { GET_AREAS } from "../store/actions/actionTypes";
import { getNewAreas } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";
import GigItem from "./DashboardItem/Items/GigItem";
import JobBrowsingDialog from "./JobBrowsing/JobBrowsingDialog";
import Sort from "./Sort";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

export default function GigCategoriesList(props) {
  const classes = useStyles();
  const { id } = props.match.params;
  const [jobsByJobCategory, setJobsByJobCategory] = useState([]);
  const pageSize = JOB_LIST_PAGE_SIZE;
  const [hasMore, setHasMore] = useState(true);
  const areas = useSelector((state) => state.reduxReducer.areas);
  const dispatch = useDispatch();
  const [haveToken, setHaveToken] = useState(false);
  const [darkState, setDarkState] = useState(
    localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.DARK_MODE])
      : null
  );
  const [sortBy, setSortBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [pages, setPages] = useState(1);
  const [jobsToRender, setJobsToRender] = useState([]);

  //#region useEffect

  useEffect(() => {
    const fetchAllAreas = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      let areaData = getAllAreas.data;
      const newAreas = getNewAreas(areaData);
      dispatch({ type: GET_AREAS, payload: newAreas });
    };
    if (!areas) {
      fetchAllAreas();
    }
  }, []);
  useEffect(() => {
    const getAllJobsByJobCategory = async (id) => {
      try {
        const result = await axios.post(API.GET_ALL_JOBS_BY_JOB_CATEGORY, {
          id,
          startIndex: 0,
          pageSize,
          searchText: searchText,
          sortBy: sortBy,
          filterData: filterData,
        });

        if (result.data) {
          console.log(result.data);
          setJobsByJobCategory(result.data);
        } else {
          setJobsByJobCategory([]);
          setHasMore(false);
        }
      } catch (error) {
        console.log({ error });
      }
    };
    if (id) {
      getAllJobsByJobCategory(id);
      window.scrollTo(0, 0);
      setHasMore(true);
      setPages(1);
      setJobsToRender([]);
    }
  }, [id, searchText, sortBy, filterData]);

  useEffect(() => {
    let jobsCount = (pages - 1) * 10 + jobsByJobCategory.length;
    if (jobsToRender.length === jobsCount && jobsCount > 0) {
      setHasMore(false);
    }
    setJobsToRender(jobsByJobCategory);

    console.log({ hasMore: hasMore });
  }, [jobsByJobCategory]);
  const _onCloseModalCategory = () => {
    handleFilterClose();
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_close_modal_category: _onCloseModalCategory,
    };
    //alert(JSON.stringify(event));
  }, []);
  //#endregion

  //#region onChange

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSortByField = (sortByField) => {
    console.log(sortByField);
    setSortBy(sortByField);
  };

  //#endregion

  //#region infinite scroll

  const fetchMoreData = () => {
    const getMoreData = async () => {
      console.log("GET MORE");
      const result = await axios.post(API.GET_ALL_JOBS_BY_JOB_CATEGORY, {
        id,
        startIndex: jobsToRender.length,
        pageSize,
        searchText: searchText,
        sortBy: sortBy,
        filterData: filterData,
      });

      if (result.data) {
        setJobsToRender(jobsToRender.concat(result.data));
        let newPages = pages + 1;
        setPages(newPages);
        if (result.data.length < pageSize) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    };
    getMoreData();
  };

  //#endregion

  //#region onClick

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  const handleFilterJobsApply = (data) => {
    setFilterData(data);
    console.log("test filter");
  };

  const handleDelete = async (e, filterDataToDelete, idToDelete) => {
    console.info("You clicked the delete icon.");

    let updatedFilterData = {
      areas: filterData.areas,
      jobCategories: filterData.jobCategories,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
    };
    if (filterDataToDelete === "area") {
      let indexOfArea = filterData.areas.indexOf(idToDelete);
      let newAreasFilter = filterData.areas;
      newAreasFilter.splice(indexOfArea, 1);
      updatedFilterData.areas = newAreasFilter;
    }

    if (filterDataToDelete === "jobCategory") {
      let indexOfJobCategories = filterData.jobCategories.indexOf(idToDelete);
      let newJobCategoriesFilter = filterData.jobCategories;
      newJobCategoriesFilter.splice(indexOfJobCategories, 1);
      updatedFilterData.jobCategories = newJobCategoriesFilter;
    }

    if (filterDataToDelete === "date") {
      updatedFilterData.dateFrom = null;
      updatedFilterData.dateTo = null;
    }

    setFilterData(updatedFilterData);
  };

  //#endregion

  return (
    <DarkMode darkState={darkState}>
      <Sticky>
        <div className="stickysearch">
          <div className="seachInput">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              onChange={(e) => handleSearchTextChange(e)}
              className="txtSearch"
              InputProps={{
                startAdornment: <SearchIcon style={{ color: "#a2a2a2" }} />,
              }}
            />
          </div>
          <div className="sort">
            <div style={{ display: "flex", alignItems: "center" }}>
              Sort by:{" "}
              <Sort
                isListJob={true}
                sortByField={handleSortByField}
                style={{ padding: 0 }}
              />
            </div>
            <div className="icon">
              <IconButton
                color="primary"
                component="span"
                onClick={handleOpenFilter}
              >
                <TuneIcon />
              </IconButton>
            </div>
          </div>
          <div className="chip">
            <div className={classes.root}>
              {filterData && filterData.areas.length > 0
                ? filterData.areas.map((area) => {
                    let areaDescription = areas[area]?.Name;
                    return (
                      <Chip
                        size="small"
                        color="primary"
                        icon={<LocationOnIcon />}
                        label={`${areaDescription}`}
                        onDelete={(e) => handleDelete(e, "area", area)}
                      />
                    );
                  })
                : null}
              {filterData && filterData.dateFrom && !filterData.dateTo ? (
                <Chip
                  size="small"
                  color="primary"
                  icon={<WatchLaterIcon />}
                  label={`${moment(filterData.dateFrom).format(
                    FORMAT.DATE_TIME_CLIENT
                  )}`}
                  onDelete={(e) => handleDelete(e, "date")}
                />
              ) : null}
              {filterData && filterData.dateFrom && filterData.dateTo ? (
                <Chip
                  size="small"
                  color="primary"
                  icon={<WatchLaterIcon />}
                  label={`${moment(filterData.dateFrom).format(
                    FORMAT.DATE_TIME_CLIENT
                  )} to ${moment(filterData.dateTo).format(
                    FORMAT.DATE_TIME_CLIENT
                  )}`}
                  onDelete={(e) => handleDelete(e, "date")}
                />
              ) : null}
              {filterData && !filterData.dateFrom && filterData.dateTo ? (
                <Chip
                  size="small"
                  color="primary"
                  icon={<WatchLaterIcon />}
                  label={`${moment(filterData.dateTo).format(
                    FORMAT.DATE_TIME_CLIENT
                  )}`}
                  onDelete={(e) => handleDelete(e, "date")}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Sticky>
      {areas ? (
        <Container maxWidth="sm" className="formPostjob">
          {jobsToRender.length > 0 ? (
            <Fragment>
              <div className="listJob">
                <List style={{ width: "100%", padding: 0 }}>
                  <InfiniteScroll
                    dataLength={jobsToRender.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<div class="loader"></div>}
                  >
                    {jobsToRender.map((item) => {
                      let currentAreas =
                        item.Area.length > 0 ? item.Area.split(",") : [];
                      let areasName = [];
                      if (currentAreas.length > 0) {
                        currentAreas.map((currentArea) => {
                          let areaName = areas[parseInt(currentArea)].Name;
                          areasName.push(areaName);
                        });
                      }
                      return (
                        <GigItem
                          key={item.Id}
                          item={item}
                          areasName={
                            areasName.length > 0 ? areasName.join(", ") : ""
                          }
                          verticalList={true}
                        />
                      );
                    })}
                  </InfiniteScroll>
                </List>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="empty">
                <img src={require("../images/empty.svg")} />
                No Results
              </div>
            </Fragment>
          )}
        </Container>
      ) : (
        <Loading />
      )}
      <JobBrowsingDialog
        openDialog={openFilter}
        handleDialogClose={handleFilterClose}
        onFilterJobsApply={handleFilterJobsApply}
        isFilterByJobCategoryId={true}
      />
    </DarkMode>
  );
}
