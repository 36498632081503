import { Button, Container, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DOMAIN, FORMAT, BUTTON_TEXT, API } from "../../config/config";

import DarkMode from "../DarkMode/DarkMode";

const ExpansionPanel = withStyles({
  root: {
    marginBottom: 16,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    minHeight: 36,
    "&$expanded": {
      minHeight: 36
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: "10px 16px"
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const PostJobPreview = (props) => {
  const {
    data,
    handleEditClick,
    resetForm,
    handleOpenSuccessDialog,
    handleOpenErrorDialog
  } = props;

  const [darkState, setDarkState] = useState(null);
  const [areas, setAreas] = useState([]);
  const [spinnerOn, setSpinnerOn] = useState(false);

  useEffect(() => {
    if (data) {
      setAreas(JSON.parse(data.areas));
    }
  }, [data]);

  const handleConfirm = async () => {
    setSpinnerOn(true);
    try {
      const result = await axios.post(API.POST_JOB, data);
      const error = result.data.error;
      if (error) {
        setSpinnerOn(false);
      } else {
        setSpinnerOn(false);
        if (resetForm) {
          resetForm(result.data, handleEditClick);
        }
      }
    } catch (error) {
      setSpinnerOn(false);
      if (handleOpenErrorDialog) {
        handleOpenErrorDialog();
      }
    }
  };

  const handleEdit = () => {
    if (handleEditClick) {
      handleEditClick();
    }
  };

  return (
    <DarkMode darkState={darkState}>
      <div className="jobdetails" style={{ marginBottom: "-60px" }}>
        <Container maxWidth="sm" style={{ padding: "0" }}>
          {data ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 10
                }}
              >
                <Avatar
                  className="avatar"
                  style={{ width: 65, height: 65, marginTop: 20 }}
                  // src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
                  src={`${DOMAIN}/profile/${
                    data.ompanyImage ? data.companyImage : data.image
                  }`}
                  //   onClick={() =>
                  //     goToGigProfile({ type: GIGGER_TYPE_CONST.GIGBOSS })
                  //   }
                />
              </div>

              <div className="title">
                <h2 style={{ textAlign: "center" }}>{data.title}</h2>
                {data.website && <div>{data.website}</div>}
                <span className="location">{data.areaDescription}</span>
                <h4>{data.payByDescription}</h4>
              </div>
              <div className="contentDetail">
                <ExpansionPanel defaultExpanded className="ExpansionPanel">
                  <ExpansionPanelSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className="ExpansionPanelSummary"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className="fwbold">Description</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className="ExpansionPanelDetails"
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item xs={12}>
                      <div className="typographyflex">
                        <span>Gig Type:</span> {data.jobCategoryDescription}
                      </div>
                      <div className="typographyflex flexcolm">
                        <span>Description:</span>{" "}
                        <span className="multiline">{data.description}</span>
                      </div>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded className="ExpansionPanel">
                  <ExpansionPanelSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    className="ExpansionPanelSummary"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className="fwbold">Requirement</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className="ExpansionPanelDetails"
                    style={{ flexDirection: "column" }}
                  >
                    {data.requirement && data.requirement.length > 0 && (
                      <div className="typographyflex flexcolm">
                        <span>Requirement:</span>{" "}
                        <span className="multiline">{data.requirement}</span>
                      </div>
                    )}
                    <div className="typographyflex">
                      <span>Pax:</span> {data.noPerson}
                    </div>
                    {data.attire && data.attire.length > 0 && (
                      <div className="typographyflex flexcolm">
                        <span>Attire:</span>{" "}
                        <span className="multiline">{data.attire}</span>
                      </div>
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {(data.location && data.location.length > 0) ||
                (areas && areas.length > 0) ? (
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      aria-controls="panel4d-content"
                      id="panel4d-header"
                      className="ExpansionPanelSummary"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography className="fwbold">Location</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className="ExpansionPanelDetails"
                      style={{ flexDirection: "column" }}
                    >
                      {data.location && data.location.length > 0 ? (
                        <div className="typographyflex">
                          <span>Location: </span> {data.location}
                        </div>
                      ) : null}
                      {areas && areas.length > 0 ? (
                        <div className="typographyflex">
                          <span>Area:</span> {data.areaDescription}
                        </div>
                      ) : null}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : null}
                <ExpansionPanel defaultExpanded className="ExpansionPanel">
                  <ExpansionPanelSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    className="ExpansionPanelSummary"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className="fwbold">Time</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className="ExpansionPanelDetails"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="typographyflex">
                      <span>Date:</span>{" "}
                      {moment(data.dateFrom).format(FORMAT.DATE_TIME_CLIENT)}
                      {data.dateTo && data.dateTo !== ""
                        ? ` to ${moment(data.dateTo).format(
                            FORMAT.DATE_TIME_CLIENT
                          )}`
                        : ""}
                    </div>
                    <div className="typographyflex">
                      <span>Time:</span>
                      {data.hourFrom}
                      {data.dateTo && data.dateTo !== ""
                        ? ` to ${data.hourTo}`
                        : ""}
                    </div>
                    <div className="typographyflex">
                      <span>Application Deadline:</span>{" "}
                      {moment(data.deadline).format(FORMAT.DATE_TIME_CLIENT)}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded className="ExpansionPanel">
                  <ExpansionPanelSummary
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                    className="ExpansionPanelSummary"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className="fwbold">Contact Us</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className="ExpansionPanelDetails"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="typographyflex">
                      <span>Phone:</span>
                      {/* <a href={`tel:${data.ContactNumber}`} className="linkt"> */}
                      {data.contactNumber}
                      {/* </a> */}
                    </div>
                    {data.contactEmail ? (
                      <div className="typographyflex">
                        <span>Email:</span>
                        {/* <a
                          href={`mailto:${job.ContactEmail}`}
                          className="linkt"
                        > */}
                        {data.contactEmail}
                        {/* </a> */}
                      </div>
                    ) : null}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div className="btnDetails">
                <Button
                  name="action"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: "100%",
                    margin: "0 16px",
                    backgroundColor: "#25D366",
                    color: "#ffffff"
                  }}
                  onClick={handleEdit}
                >
                  {BUTTON_TEXT.BACK}
                </Button>
                <Button
                  name="action"
                  type="submit"
                  variant="contained"
                  style={{
                    width: "100%",
                    margin: "0 16px",
                    maxWidth: "552px"
                  }}
                  color="primary"
                  onClick={handleConfirm}
                >
                  {BUTTON_TEXT.SUBMIT}
                </Button>
              </div>
            </>
          ) : null}
        </Container>
        <div style={{ height: "50px" }}></div>
      </div>
    </DarkMode>
  );
};

export default PostJobPreview;
