import List from "@material-ui/core/List";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  API,
  GIG_FOR_YOU_PAGE_SIZE,
  LOCAL_STORAGE_KEY,
} from "../config/config";
import GigItem from "./DashboardItem/Items/GigItem";

const ListJob = (props) => {
  const { sortBy, searchText, filterData, isGigForYou, onForeGround } = props;
  const [jobs, setJobs] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [jobsToRender, setJobsToRender] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [areas, setAreas] = useState([]);
  const [pages, setPages] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      setAreas(getAllAreas.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let jobsCount = (pages - 1) * 10 + jobs.length;
    if (
      isGigForYou ||
      (jobsToRender.length === jobsCount && jobsCount > 0 && pages > 1)
    ) {
      setHasMore(false);
    }

    setJobsToRender(jobs);

    //setHasMore(!isGigForYou ? true : false);
    console.log({ hasMore: hasMore });

    // if (jobs.length <= pageSize) {
    //   setHasMore(false);
    // }
  }, [jobs]);
  console.log("LIST JOB");
  const fetchMoreData = () => {
    const getMoreData = async () => {
      if (!isGigForYou) {
        const getSortedJob = await axios.post(
          API.GET_SORTED_JOBS_BY_GIGGER_ID,
          {
            token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
            pageSize: pageSize,
            startIndex: jobsToRender.length,
            searchText: searchText,
            sortBy: sortBy,
            filterData: filterData,
          }
        );
        console.log(getSortedJob.data);
        setJobsToRender(jobsToRender.concat(getSortedJob.data));
        let newPages = pages + 1;
        setPages(newPages);
        if (getSortedJob.data.length < pageSize) {
          setHasMore(false);
        }
      }
    };
    getMoreData();
  };
  useEffect(() => {
    const fetchData = async () => {
      const getAllJobsWithIndex = await axios.post(
        API.GET_SORTED_JOBS_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          pageSize: isGigForYou ? GIG_FOR_YOU_PAGE_SIZE : pageSize,
          startIndex: 0,
          searchText: searchText,
          sortBy: sortBy,
          filterData: filterData,
        }
      );

      let allJobsWithIndex = getAllJobsWithIndex.data;

      setJobs(allJobsWithIndex);
    };
    console.log({ sortBy });
    if (onForeGround) {
      //alert("FETCH DATA onForeGround");
      fetchData();
    } else {
      //alert("FETCH DATA Normal");
      fetchData();
    }
    setHasMore(true);
    setPages(1);
    setJobsToRender([]);
    window.scrollTo(0, 0);
  }, [filterData, searchText, sortBy, onForeGround]);

  return (
    <div className="listJob">
      <List style={{ width: "100%", padding: 0 }}>
        {jobs.length > 0 && jobsToRender.length > 0 && areas.length > 0 ? (
          <InfiniteScroll
            dataLength={jobsToRender.length}
            next={fetchMoreData}
            hasMore={isGigForYou ? false : hasMore}
            loader={<div class="loader"></div>}
          >
            {jobsToRender.map((item) => {
              let currentAreas =
                item.Area.length > 0 ? item.Area.split(",") : [];
              let areasName = [];
              if (currentAreas.length > 0) {
                currentAreas.map((currentArea) => {
                  let areaName = areas.find(
                    (area) => area.Id === parseInt(currentArea)
                  )?.Name;
                  areasName.push(areaName);
                });
              }
              return (
                <GigItem
                  key={item.Id}
                  verticalList={false}
                  areasName={areasName.length > 0 ? areasName.join(", ") : ""}
                  item={item}
                />
              );
            })}
          </InfiniteScroll>
        ) : null}
      </List>
    </div>
  );
};

export default ListJob;
