import React from "react";
import { Fragment } from "react";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-168581306-1";
const GA_TRACKING_ID = "G-RZ3060GL2H";
export const GATracking = () => {
  ReactGA.initialize(TRACKING_ID);
  let baseUrl = window.location.pathname + window.location.search;
  console.log({ baseUrl });
  ReactGA.pageview(baseUrl);
  console.log({ ReactGA });
  return <Fragment></Fragment>;
};
