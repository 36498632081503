import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  CLIENT_DOMAIN,
  DOMAIN,
  GIG_LINKS,
  WEBVIEW_ACTION,
  FORMAT,
  API,
  LOCAL_STORAGE_KEY,
  LABELS,
} from "../../../config/config";
import moment from "moment";
import { ON_SEND_MESSAGE } from "../../../store/actions/actionTypes";
import { sendMessage } from "../../../ultils/ultils";
import { text } from "body-parser";
export default function GigItem({
  verticalList,
  item,
  areasName,
  showApplications,
  isMyCompletedGigs,
  isGigBossExpiredGigs,
  isLiked = false,
}) {
  const [isAllow, setIsAllow] = useState(true);
  const [isReviewExisting, setIsReviewExisting] = useState(true);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const dispatch = useDispatch();
  const onSendMessage = useSelector(
    (state) => state.reduxReducer.onSendMessage
  );

  const goToGigDetail = async () => {
    if (parseInt(currentId)) {
      if (isAllow && !onSendMessage) {
        setIsAllow(false);
        dispatch({ type: ON_SEND_MESSAGE });
        sendMessage({
          action: WEBVIEW_ACTION.JOB_DETAIL,
          url: `${CLIENT_DOMAIN}${GIG_LINKS.GIG_DETAILS}/${item.Code}`,
          jobTitle: item.Title,
          canShare: true,
          isLiked: isLiked,
        });
      }
    } else {
      window.open(`${CLIENT_DOMAIN}${GIG_LINKS.GIG_DETAILS}/${item.Code}`);
    }
  };
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        dispatch({ type: ON_SEND_MESSAGE });
        setIsAllow(true);
      }, 1000);
    }
  }, [isAllow]);

  useEffect(() => {
    const fetchData = async () => {
      if (item) {
        let data = {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          jobId: item.Id,
        };
        let isHideLeaveReview = true;
        if (isMyCompletedGigs) {
          const isReviewExistingByJobIdAndReviewerId = await axios.post(
            API.IS_REVIEW_EXISTING_BY_JOB_ID_AND_REVIEWER_ID,
            data
          );
          isHideLeaveReview = isReviewExistingByJobIdAndReviewerId.data;
        }

        if (isGigBossExpiredGigs) {
          const isReviewsForAllGiggerJobsExistingByJobIdAndReviewerId = await axios.post(
            API.IS_REVIEWS_FOR_ALL_GIGGER_JOBS_EXISTING_BY_JOB_ID_AND_REVIEWER_ID,
            data
          );
          isHideLeaveReview =
            isReviewsForAllGiggerJobsExistingByJobIdAndReviewerId.data;
        }

        setIsReviewExisting(isHideLeaveReview);
      }
    };

    fetchData();
  }, [item, isMyCompletedGigs, isGigBossExpiredGigs]);

  const showApplicationText = (numberOfApplication) => {
    let applicationText = "";
    if (numberOfApplication >= 2) {
      applicationText = `${numberOfApplication} Applications received!`;
    } else {
      applicationText = `${numberOfApplication} Application received!`;
    }
    return applicationText;
  };
  return (
    <Fragment>
      {item && (
        <Fragment>
          {verticalList ? (
            <ListItem
              button
              className="listcontent"
              onClick={() => goToGigDetail(item)}
            >
              <Avatar
                className="avatar"
                src={`${DOMAIN}/profile/${
                  item.CompanyImage ? item.CompanyImage : item.Image
                }`}
              />
              <div className="content">
                <div>
                  <div className="title">{item.Title}</div>
                  <span className="location">
                    {areasName}{" "}
                    <span>
                      {moment(item.CreatedDate).format(FORMAT.DATE_TIME_CLIENT)}
                    </span>
                  </span>
                  <span className="time">{item.payByDescription}</span>
                  <span className="show-applications">
                    {showApplications &&
                      item.TotalApplications > 0 &&
                      `${showApplicationText(item.TotalApplications)}`}
                  </span>
                  <span className="leave-review">
                    {!isReviewExisting ? LABELS.LEAVE_A_REVIEW : ""}
                  </span>
                </div>
              </div>
            </ListItem>
          ) : (
            <li>
              <ListItem
                button
                className="listcontent"
                onClick={() => goToGigDetail(item)}
              >
                <Avatar
                  className="avatar"
                  src={`${DOMAIN}/profile/${
                    item.CompanyImage ? item.CompanyImage : item.Image
                  }`}
                />
                <div className="content">
                  <div>
                    <div className="title">{item.Title} </div>
                    <span className="location">
                      {areasName}{" "}
                      <span>
                        {moment(item.CreatedDate).format(
                          FORMAT.DATE_TIME_CLIENT
                        )}
                      </span>
                    </span>
                    <span className="time">{item.payByDescription}</span>
                    <span className="show-applications">
                      {showApplications &&
                        item.TotalApplications > 0 &&
                        `${showApplicationText(item.TotalApplications)}`}
                    </span>
                    <span className="leave-review">
                      {!isReviewExisting ? LABELS.LEAVE_A_REVIEW : ""}
                    </span>
                  </div>
                </div>
              </ListItem>
            </li>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
