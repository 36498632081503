import React from "react";
import { sendMessage } from "../../ultils/ultils";
import { WEBVIEW_ACTION, CLIENT_DOMAIN, DOMAIN } from "../../config/config";

export default function SliderItem({ image, link }) {
  const openLink = async () => {
    if (link) {
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_SLIDER_URL,
        link: link,
      });
    }
  };
  return (
    <div>
      <div
        onClick={openLink}
        //data-id={data.link}
        className="slider-item"
        // style={{
        //   backgroundImage: `url(${DOMAIN}${image})`,
        // }}
      >
        <img src={`${DOMAIN}${image}`} />
      </div>
    </div>
  );
}
