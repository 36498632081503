import { Button, Container, Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardTimePicker } from "@material-ui/pickers";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import axios from "axios";
import moment from "moment";
import "rc-datepicker/lib/style.css";
import React, { createRef, Fragment, memo, useEffect, useState } from "react";
import { isIE, isSafari } from "react-device-detect";
import {
  AMOUNTOFPAY,
  API,
  LOCAL_STORAGE_KEY,
  PAYMENT_TERM_VALUES,
  POST_GIG_FORM,
  SALARY_KEYS,
  WEBVIEW_ACTION,
  BUTTON_TEXT,
} from "../config/config";
import {
  convertFrom12To24Format,
  convertFrom24To12Format,
} from "../helpers/TimeHelpers";
import { sendMessage, setAxiosToken, validateUrl } from "../ultils/ultils";
import DialogComponent from "./Components/DialogComponent";
import DarkMode from "./DarkMode/DarkMode";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MissingUserInfoDialog from "./JobDetails/MissingUserInfoDialog";
import PostJobErrorDialog from "./PostJob/PostJobErrorDialog";
import PostJobPreview from "./PostJob/PostJobPreview";
import { getPayByDescription } from "../helpers/PostJobHelpers";

const paymentTerms = PAYMENT_TERM_VALUES;
const initialForm = POST_GIG_FORM.INITIAL_FORM;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PostJob = memo((props) => {
  console.log("POST JOBS");
  const [jobCategories, setJobCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [formData, setFormData] = useState(initialForm);
  const [skills, setSkills] = useState(null);
  const [fieldValidation, setFieldValidation] = useState({
    errors: {},
  });
  const [currentJobCode, setCurrentJobCode] = useState("");
  const [canSendMessage, setCanSendMessage] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [keyReset, setKeyReset] = useState(0);
  const [isHourToDisabled, setIsHourToDisabled] = useState(true);
  const [hourlySalary, setHourlySalary] = useState("");
  const [hourlyUpToSalary, setHourlyUpToSalary] = useState("");
  const [flatFeeSalary, setFlatFeeSalary] = useState("");
  const [flatFeeUpToSalary, setFlatFeeUpToSalary] = useState("");
  const [dailySalary, setDailySalary] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [dailyUpToSalary, setDailyUpToSalary] = useState("");
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [haveToken, setHaveToken] = useState(false);
  const [os, setOS] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [userContactNumber, setUserContactNumber] = useState("");
  // Toggle Dark Mode
  const [darkState, setDarkState] = useState(null);
  const [currentHourFrom, setCurrentHourFrom] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [currentHourTo, setCurrentHourTo] = useState(
    new Date().setHours(23, 59, 0, 0)
  );
  const [hideURLTextBox, setHideURLTextBox] = useState(true);
  const [selectedAreas, setSelectedAreas] = useState("");
  const [openMissingUserInfoDialog, setOpenMissingUserInfoDialog] = useState(
    false
  );
  const [refValues, setRefValues] = useState({});
  const [
    isShowPostJobMandatoryErrorDialog,
    setIsShowPostJobMandatoryErrorDialog,
  ] = useState(false);
  const [isShowPostJobPreview, setIsShowPostJobPreview] = useState(false);
  const [postJobPreviewData, setPostJobPreviewData] = useState(null);
  const [jobCategoryInputValue, setJobCategoryInputValue] = useState("");

  //#region onChange
  useEffect(() => {
    console.log("ON CHANGE");
  }, [formData]);
  const onDataChange = (e) => {
    if (
      e.target.type === "radio" &&
      e.target.value === AMOUNTOFPAY.NOT_STATED
    ) {
      setFormData({ ...formData, ["salary"]: "" });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (
      e.target.type === "radio" &&
      e.target.value === POST_GIG_FORM.APPLY_VIA.FORM
    ) {
      setHideURLTextBox(false);
    }

    if (
      e.target.type === "radio" &&
      e.target.value === POST_GIG_FORM.APPLY_VIA.WHATSAPP
    ) {
      setHideURLTextBox(true);
    }
  };

  const onAreaChange = (event, newValues) => {
    let areasToSelect = [];
    newValues.map((newValue) => {
      areasToSelect.push(newValue.value);
    });
    setSelectedAreas(areasToSelect.toString());
    setFormData({ ...formData, areas: newValues });
  };

  const onJobCategoryChange = (event, newValue) => {
    //console.log(event.target.value);
    //formData["jobCategoryId"] = parseInt(event.target.value);

    if (newValue) {
      setFormData({
        ...formData,
        jobCategories: newValue
      });
    }
  };

  const onPaymentTermChange = (event, newValue) => {
    if (newValue) {
      setFormData({
        ...formData,
        paymentTerm: newValue.value,
        paymentTermObj: newValue,
      });
    }
  };

  const _onMessage = async (data) => {
    if (data.token) {
      //alert("ON_POST_GIG");
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_ID, data.currentId);
      setAxiosToken(data.token);
      //alert(localStorage.token);
      if (data.os === "ios") {
        setOS(data.os);
        document.body.classList.add("ios");
      }
      setHaveToken(true);
    }
  };
  const isMissingInfo = (getUserProfileById) => {
    return (
      !getUserProfileById.data.CompanyName ||
      getUserProfileById.data.CompanyName.length === 0 ||
      !getUserProfileById.data.ContactName ||
      getUserProfileById.data.ContactName.length === 0
    );
  };
  const _onCheckInfo = async () => {
    try {
      const getUserProfileById = await axios.post(API.GET_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
      });
      //alert(JSON.stringify(getUserProfileById.data));
      if (isMissingInfo(getUserProfileById)) {
        setOpenMissingUserInfoDialog(true);
      }
    } catch (error) { }
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_post_gig: _onMessage,
      toggle_darkmode: _onToggle,
      check_info: _onCheckInfo,
    };
  }, []);
  // const toggleDarkMode = (darkState) => {
  //   darkState
  //     ? document.body.classList.add("darkmode")
  //     : document.body.classList.remove("darkmode");
  //   setDarkState(darkState);
  // };
  const onDateFromPickerChange = (e) => {
    // let isValidDate = moment(selectedDate).isValid();

    // if (isValidDate) {
    //   setFormData({ ...formData, dateFrom: selectedDate.toISOString() });
    // } else {
    //   setFormData({ ...formData, dateFrom: null });
    // }
    setFormData({ ...formData, dateFrom: e.target.value });
  };

  const onDateToPickerChange = (e) => {
    // let isValidDate = moment(selectedDate).isValid();

    setFormData({ ...formData, dateTo: e.target.value });

    const disableHourTo = !e.target.value || e.target.value === "";
    setIsHourToDisabled(disableHourTo);
  };

  const onDeadlinePickerChange = (e) => {
    setFormData({ ...formData, deadline: e.target.value });
  };

  const onHourFromChange = (e) => {
    console.log(e);
    const formattedHourFrom = convertFrom24To12Format(e.target.value);
    setFormData({
      ...formData,
      ["hourFrom"]: formattedHourFrom,
    });
  };

  const onHourToChange = (e) => {
    console.log(e);
    setFormData({
      ...formData,
      ["hourTo"]: convertFrom24To12Format(e.target.value),
    });
  };

  const onCurrentHourFromChange = (selectedTime) => {
    console.log(selectedTime);
    setCurrentHourFrom(selectedTime);

    const formattedTime = selectedTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const disableHourTo = formattedTime === "12:00 AM";
    setIsHourToDisabled(disableHourTo);
  };

  const onCurrentHourToChange = (selectedTime) => {
    console.log(selectedTime);
    setCurrentHourTo(selectedTime);
  };

  const onCurrencyTextClick = (e, value) => {
    setFormData({ ...formData, amountOfPay: value });
  };

  // const onSkillsChange = (event, value) => {
  //   event.persist();
  //   console.log(value);
  //   console.log(event);
  //   value.forEach((skill) => {
  //     if (!selectedSkills.some((item) => item === skill.Name)) {
  //       setSelectedSkills((oldArray) => [...oldArray, skill.Name]);
  //     }
  //   });
  // };

  //#endregion

  const setDefaultRef = () => {
    let titleRef = createRef();
    let descriptionRef = createRef();
    let requirementRef = createRef();
    let locationRef = createRef();
    let attireRef = createRef();
    let contactEmailRef = createRef();
    let formURLRef = createRef();

    setRefValues({
      Title: titleRef,
      Description: descriptionRef,
      Requirement: requirementRef,
      Location: locationRef,
      Attire: attireRef,
      ContactEmail: contactEmailRef,
      FormURL: formURLRef,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setDefaultRef();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const getAllJobCategoriesResults = await axios.get(
        API.GET_ALL_JOB_CATEGORIES
      );
      //setJobCategories(getAllJobCategoriesResults.data);
      let allJobCategories = [];
      getAllJobCategoriesResults.data.forEach((jobCategory) => {
        allJobCategories.push({
          value: jobCategory.Id,
          label: jobCategory.Name,
        });
      });
      setJobCategories(allJobCategories);

      // const getAllSkills = await axios.get(API.GET_ALL_SKILLS);
      // console.log(getAllSkills.data);
      // setSkills(getAllSkills.data);

      let allAreas = [];
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      getAllAreas.data.forEach((area) => {
        allAreas.push({
          value: area.Id,
          label: area.Name,
        });
      });
      setAreas(allAreas);

      const getUserProfileById = await axios.post(API.GET_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
      });
      if (getUserProfileById.data) {
        const { PhoneNumber } = getUserProfileById.data;
        setFormData({ ...formData, contactNumber: PhoneNumber });
        setUserContactNumber(PhoneNumber);
      }
    };
    if (haveToken) {
      fetchData();
    }
  }, [haveToken]);
  useEffect(() => {
    if (canSendMessage) {
      sendMessage({
        action: WEBVIEW_ACTION.JOB_DETAIL,
        jobCode: currentJobCode,
        //jobCode: "lWI99949",
      });
      setTimeout(() => {
        setCanSendMessage(false);
        setCurrentJobCode("");
      }, 1000);
    }
  }, [canSendMessage]);
  const handleOpenErrorDialog = () => {
    setOpenErrorDialog(true);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setCanSendMessage(true);
    setOpenSuccessDialog(false);
  };

  const handleClosePostJobPreview = () => {
    setIsShowPostJobPreview(false);
  };

  //#region Validations

  const handleValidation = () => {
    let errors = {};
    let isValidForm = true;

    if (
      refValues["Title"].current !== null &&
      (!refValues["Title"].current.value ||
        refValues["Title"].current.value.trim().length === 0)
    ) {
      isValidForm = false;
      errors["title"] = POST_GIG_FORM.ERROR_MESSAGE.TITLE;
    }

    if (
      refValues["Description"].current !== null &&
      (!refValues["Description"].current.value ||
        refValues["Description"].current.value.trim().length === 0)
    ) {
      isValidForm = false;
      errors["description"] = POST_GIG_FORM.ERROR_MESSAGE.DESCRIPTION;
    }

    if (
      !formData["jobCategories"] ||
      formData["jobCategories"].length === 0
    ) {
      isValidForm = false;
      errors["jobCategoryId"] = POST_GIG_FORM.ERROR_MESSAGE.GIG_TYPE;
    }

    if (!formData["dateFrom"] || formData["dateFrom"].trim().length === 0) {
      isValidForm = false;
      errors["dateFrom"] = POST_GIG_FORM.ERROR_MESSAGE.START_DATE;
    }

    if (
      formData["dateFrom"] &&
      formData["dateFrom"] < moment(new Date()).format("YYYY-MM-DD")
    ) {
      isValidForm = false;
      errors["dateFrom"] = POST_GIG_FORM.ERROR_MESSAGE.START_DATE_CURRENT_DATE;
    }

    if (
      formData["dateFrom"] &&
      formData["dateTo"] &&
      formData["dateFrom"].trim().length > 0 &&
      formData["dateTo"].trim().length > 0
    ) {
      if (formData["dateFrom"].trim() > formData["dateTo"].trim()) {
        isValidForm = false;
        errors["dateTo"] = POST_GIG_FORM.ERROR_MESSAGE.END_DATE;
      }
    }

    switch (formData.amountOfPay) {
      case AMOUNTOFPAY.HOURLY: {
        if (hourlySalary === "" || parseFloat(hourlySalary) === 0) {
          isValidForm = false;
          errors["hourlySalary"] = POST_GIG_FORM.ERROR_MESSAGE.SALARY;
        }

        if (parseFloat(hourlySalary) < 0) {
          isValidForm = false;
          errors["hourlySalary"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_VALUE;
        }
        break;
      }
      case AMOUNTOFPAY.MONTHLY: {
        if (monthlySalary === "" || parseFloat(monthlySalary) === 0) {
          isValidForm = false;
          errors["monthlySalary"] = POST_GIG_FORM.ERROR_MESSAGE.SALARY;
        }

        if (parseFloat(monthlySalary) < 0) {
          isValidForm = false;
          errors["monthlySalary"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_VALUE;
        }
        break;
      }
      case AMOUNTOFPAY.DAILY: {
        if (dailySalary === "" || parseFloat(dailySalary) === 0) {
          isValidForm = false;
          errors["dailySalary"] = POST_GIG_FORM.ERROR_MESSAGE.SALARY;
        }

        if (parseFloat(dailySalary) < 0) {
          isValidForm = false;
          errors["dailySalary"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_VALUE;
        }
        break;
      }
      case AMOUNTOFPAY.FLAT_FEE: {
        if (flatFeeSalary === "" || parseFloat(flatFeeSalary) === 0) {
          isValidForm = false;
          errors["flatFeeSalary"] = POST_GIG_FORM.ERROR_MESSAGE.SALARY;
        }

        if (parseFloat(flatFeeSalary) < 0) {
          isValidForm = false;
          errors["flatFeeSalary"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_VALUE;
        }
        break;
      }
      case AMOUNTOFPAY.NOT_STATED: {
      }
      default: {
        isValidForm = true;
        errors["hourlySalary"] = "";
        errors["hourlyUpToSalary"] = "";
        errors["flatFeeSalary"] = "";
        errors["flatFeeUpToSalary"] = "";
        errors["dailySalary"] = "";
        errors["dailyUpToSalary"] = "";
        break;
      }
    }

    if (!formData["amountOfPay"] || parseInt(formData["amountOfPay"]) === 0) {
      isValidForm = false;
      errors["amountOfPay"] = POST_GIG_FORM.ERROR_MESSAGE.AMOUNT_OF_PAY;
    }

    if (!formData["paymentTerm"] || parseInt(formData["paymentTerm"]) === 0) {
      isValidForm = false;
      errors["paymentTerm"] = POST_GIG_FORM.ERROR_MESSAGE.PAYMENT_TERM;
    }

    if (!formData["noPerson"] || parseInt(formData["noPerson"]) <= 0) {
      isValidForm = false;
      errors["noPerson"] = POST_GIG_FORM.ERROR_MESSAGE.NO_PERSON;
    }

    let availableDot =
      formData["noPerson"] && formData["noPerson"].toString().indexOf(".") > 0;
    if (availableDot) {
      isValidForm = false;
      errors["noPerson"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_VALUE;
    }

    // if (selectedSkills.length === 0) {
    //   isValidForm = false;
    //   errors["skills"] = POST_GIG_FORM.ERROR_MESSAGE.SKILLS;
    // }

    if (
      !formData["contactNumber"] ||
      formData["contactNumber"].trim().length === 0
    ) {
      isValidForm = false;
      errors["contactNumber"] = POST_GIG_FORM.ERROR_MESSAGE.CONTACT_NUMBER;
    }

    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      refValues["ContactEmail"].current !== null &&
      refValues["ContactEmail"].current.value.length > 0 &&
      !refValues["ContactEmail"].current.value.match(mailformat)
    ) {
      isValidForm = false;
      errors["contactEmail"] = POST_GIG_FORM.ERROR_MESSAGE.EMAIL;
    }

    if (!hideURLTextBox && refValues["FormURL"].current !== null) {
      if (refValues["FormURL"].current.value.length === 0) {
        isValidForm = false;
        errors["applyFormUrl"] = POST_GIG_FORM.ERROR_MESSAGE.APPLY_VIA_FORM_URL;
      } else if (!validateUrl(refValues["FormURL"].current.value)) {
        isValidForm = false;
        errors["applyFormUrl"] = POST_GIG_FORM.ERROR_MESSAGE.INVALID_FORM_URL;
      }
    }

    if (!formData["deadline"] || formData["deadline"].trim().length === 0) {
      isValidForm = false;
      errors["deadline"] = POST_GIG_FORM.ERROR_MESSAGE.DEADLINE;
    }

    if (formData["deadline"] && formData["deadline"].trim().length > 0) {
      // if (formData["dateFrom"] && formData["dateFrom"].length > 0) {
      //   if (formData["dateFrom"].trim() > formData["deadline"].trim()) {
      //     isValidForm = false;
      //     errors["deadline"] = POST_GIG_FORM.ERROR_MESSAGE.DATEFROM_DEADLINE;
      //   }
      // }
      let today = moment(new Date()).format("YYYY-MM-DD");
      if (formData["deadline"] < today) {
        isValidForm = false;
        errors["deadline"] = POST_GIG_FORM.ERROR_MESSAGE.DEADLINE_CURRENTDATE;
      }

      if (formData["dateTo"] && formData["dateTo"].length > 0) {
        if (formData["dateTo"].trim() < formData["deadline"].trim()) {
          isValidForm = false;
          errors["deadline"] = POST_GIG_FORM.ERROR_MESSAGE.DATETO_DEADLINE;
        }
      }
    }

    setFieldValidation({ errors: errors });
    let noError = true;
    for (const index of Object.keys(errors)) {
      if (errors[index].length > 0) {
        noError = false;
        break;
      }
    }
    if (noError) {
      setFieldValidation({ errors: {} });
    } else {
      setIsShowPostJobMandatoryErrorDialog(true);
    }
    return noError;
  };

  //#endregion

  //#region onSubmit

  const onConfirm = async (e) => {
    e.preventDefault();
    //setSpinnerOn(true);

    const getUserProfileById = await axios.post(API.GET_GIGGER_BY_TOKEN, {
      token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
    });
    if (getUserProfileById.data) {
      const {
        CompanyName,
        ContactName,
        Image,
        CompanyImage,
        Website,
      } = getUserProfileById.data;

      if (
        !CompanyName ||
        CompanyName.length === 0 ||
        !ContactName ||
        ContactName.length === 0
      ) {
        setOpenMissingUserInfoDialog(true);
        setSpinnerOn(false);
        clearAllSalaryField();
        setFormData({ ...initialForm, contactNumber: userContactNumber });
        let newKey = keyReset + 1;
        setKeyReset(newKey);
        setHideURLTextBox(true);
        return;
      }

      if (handleValidation()) {
        try {
          let salary = "";
          switch (formData.amountOfPay) {
            case AMOUNTOFPAY.HOURLY: {
              salary = hourlySalary;
              break;
            }
            case AMOUNTOFPAY.FLAT_FEE: {
              salary = flatFeeSalary;
              break;
            }
            case AMOUNTOFPAY.DAILY: {
              salary = dailySalary;
              break;
            }
            case AMOUNTOFPAY.MONTHLY: {
              salary = monthlySalary;
              break;
            }
            default: {
              salary = "";
              break;
            }
          }

          const hourFrom = new Date(currentHourFrom).toLocaleString("en-GB", {
            hour: "numeric",
            minute: "numeric",
          });
          const hourTo = new Date(currentHourTo).toLocaleString("en-GB", {
            hour: "numeric",
            minute: "numeric",
          });

          if (formData.hourFrom === "") {
            formData.hourFrom = "12:00 AM";
          }

          if (formData.hourTo === "") {
            formData.hourTo = "11:59 PM";
          }

          let currentAreas =
            selectedAreas.length > 0 ? selectedAreas.split(",") : [];
          let areasName = [];
          if (currentAreas.length > 0) {
            currentAreas.map((currentArea) => {
              let areaName = areas.find(
                (area) => area.value === parseInt(currentArea)
              ).label;
              areasName.push(areaName);
            });
          }

          let categoriesValueArr = [];
          let categoryDescriptionArr = [];
          formData.jobCategories.map(category => {
            categoryDescriptionArr.push(category.label);
            categoriesValueArr.push(category.value);
          })

          let payByDescription = getPayByDescription(
            formData.amountOfPay,
            salary
          );

          let data = {
            title: refValues["Title"].current?.value ?? "",
            //jobCategoryId: formData.jobCategoryId,
            description: refValues["Description"].current?.value ?? "",
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            hourFrom: convertFrom12To24Format(formData.hourFrom),
            hourTo: convertFrom12To24Format(formData.hourTo),
            salary: salary,
            amountOfPay: formData.amountOfPay,
            requirement: refValues["Requirement"].current?.value ?? "",
            paymentTerm: formData.paymentTerm,
            noPerson: formData.noPerson,
            token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
            location: refValues["Location"].current?.value ?? "",
            areas: JSON.stringify(selectedAreas),
            jobCategories: categoriesValueArr.join(","),
            attire: refValues["Attire"].current?.value ?? "",
            contactNumber: formData.contactNumber,
            contactEmail: refValues["ContactEmail"].current?.value ?? "",
            applyVia: formData.applyVia,
            applyFormUrl: refValues["FormURL"].current?.value ?? "",
            deadline: formData.deadline,
            areaDescription: areasName.length > 0 ? areasName.join(", ") : "",
            jobCategoryDescription: categoryDescriptionArr.length > 0
              ? categoryDescriptionArr.join(", ")
              : "",
            image: Image,
            companyImage: CompanyImage,
            website: Website,
            payByDescription: payByDescription,
          };

          let newFormData = { ...formData };
          newFormData.applyFormUrl = data.applyFormUrl;
          newFormData.title = data.title;
          newFormData.description = data.description;
          newFormData.requirement = data.requirement;
          newFormData.location = data.location;
          newFormData.attire = data.attire;
          newFormData.contactEmail = data.contactEmail;
          setFormData(newFormData);
          setAxiosToken(localStorage[LOCAL_STORAGE_KEY.TOKEN]);
          setIsShowPostJobPreview(true);
          setPostJobPreviewData(data);
        } catch (error) {
          setSpinnerOn(false);
          setOpenErrorDialog(true);
        }
      } else {
      }
    }

    // setOpenSuccessDialog(true);
    // TESTING
    // sendMessage({
    //   action: WEBVIEW_ACTION.JOB_DETAIL,
    //   jobCode: "lWI99949",
    // });
    //
  };
  useEffect(() => {
    sendMessage({
      action: WEBVIEW_ACTION.ON_PREVIEW,
      previewStatus: isShowPostJobPreview,
    });
  }, [isShowPostJobPreview]);
  const clearAllSalaryField = () => {
    setHourlySalary("");
    setMonthlySalary("");
    setHourlyUpToSalary("");
    setFlatFeeSalary("");
    setFlatFeeUpToSalary("");
    setDailySalary("");
    setDailyUpToSalary("");
  };

  const resetForm = (currentJobCode, closePreviewDialog) => {
    setOpenSuccessDialog(true);
    clearAllSalaryField();
    setFormData({ ...initialForm, contactNumber: userContactNumber });
    let newKey = keyReset + 1;
    //setCurrentJobCode(result.data);
    setCurrentJobCode(currentJobCode);
    setKeyReset(newKey);
    setHideURLTextBox(true);
    setDefaultRef();

    if (closePreviewDialog) {
      closePreviewDialog();
    }
  };

  //#endregion

  const palletType = darkState ? "dark" : "light";

  const _onToggle = async (data) => {
    setDarkState(data.darkMode);
  };

  //#region Missing User Info Dialog

  const handleCloseMissingUserInfoDialog = () => {
    setOpenMissingUserInfoDialog(false);
  };

  //#endregion

  //#region Post Job Mandatory Error Dialog

  const handleCloseMandatoryErrorDialog = () => {
    setIsShowPostJobMandatoryErrorDialog(false);
  };

  //#endregion

  return (
    <DarkMode darkState={darkState}>
      {haveToken /* !== "null" */ && !isShowPostJobPreview && (
        <Container maxWidth="sm" className="formPostjob formPostjobPadding">
          <div className="textfillBox" style={{ padding: "16px" }}>
            {
              /* skills && */ jobCategories ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>
                      Receive applications via (select one){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <RadioGroup
                      aria-label="apply"
                      name="applyVia"
                      value={formData.applyVia}
                      onChange={(e) => onDataChange(e)}
                    >
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={POST_GIG_FORM.APPLY_VIA.WHATSAPP}
                          control={<Radio color="primary" />}
                          label={POST_GIG_FORM.APPLY_VIA.WHATSAPP}
                        />
                      </div>
                      <div
                        style={{ display: "flex" }}
                        className="post-gig-form"
                      >
                        <FormControlLabel
                          value={POST_GIG_FORM.APPLY_VIA.FORM}
                          control={<Radio color="primary" />}
                          label={POST_GIG_FORM.APPLY_VIA.FORM}
                        />
                      </div>
                    </RadioGroup>
                  </Grid>
                  {!hideURLTextBox ? (
                    <Grid item xs={12}>
                      <TextField
                        inputProps={{
                          autoCapitalize: "none",
                          inputMode: "url",
                        }}
                        type="text"
                        variant="outlined"
                        label="URL"
                        name="applyFormUrl"
                        required
                        className="TextField"
                        inputRef={refValues["FormURL"]}
                        key={keyReset}
                        defaultValue={formData.applyFormUrl}
                      />
                      <span className="error" style={{ color: "red" }}>
                        {fieldValidation.errors["applyFormUrl"]}
                      </span>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <TextField
                      inputProps={{
                        autoCapitalize: os === "ios" ? "word" : "words",
                      }}
                      name="title"
                      required
                      variant="outlined"
                      label="Title"
                      type="text"
                      className="TextField"
                      inputRef={refValues["Title"]}
                      key={keyReset}
                      defaultValue={formData.title}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["title"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      aria-label="minimum height"
                      label="Gig Description"
                      required
                      multiline
                      type="text"
                      variant="outlined"
                      className="TextField"
                      inputRef={refValues["Description"]}
                      key={keyReset}
                      defaultValue={formData.description}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["description"]}
                    </span>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <TextField
                      multiline
                      label="Gig Scope"
                      variant="outlined"
                      name="jobScope"
                      value={formData.jobScope}
                      aria-label="minimum height"
                      className="TextField"
                      onChange={(e) => onDataChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      multiline
                      aria-label="minimum height"
                      label="Gig Requirements"
                      name="requirement"
                      variant="outlined"
                      className="TextField"
                      inputRef={refValues["Requirement"]}
                      key={keyReset}
                      defaultValue={formData.requirement}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      label="Pax"
                      className="form-control"
                      type="number"
                      variant="outlined"
                      name="noPerson"
                      min="1"
                      step="1"
                      defaultValue="1"
                      className="TextField"
                      value={formData.noPerson}
                      onChange={(e) => onDataChange(e)}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["noPerson"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      id="jobCategoryId"
                      multiple
                      limitTags={2}
                      clearOnBlur={true}
                      disableClearable={true}
                      key={keyReset}
                      options={jobCategories}
                      getOptionLabel={(option) => option.label}
                      onChange={onJobCategoryChange}
                      disableClearable={true}
                      disableCloseOnSelect={true}
                      defaultValue={formData.jobCategories}
                      renderOption={(option, { selected }) => (
                        <Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                          />
                          {jobCategories.find((category) => category.value === option.value)?.label}
                        </Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gig Type"
                          variant="outlined"
                          required
                        />
                      )}
                      inputValue={jobCategoryInputValue}
                      onInputChange={(event, newInputValue) => {
                        setJobCategoryInputValue(newInputValue);
                        if (newInputValue === "") {
                          setFormData({
                            ...formData,
                            jobCategories: formData.jobCategories,
                          });
                        }
                      }}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["jobCategoryId"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      id="area"
                      multiple
                      key={keyReset}
                      options={areas}
                      style={{ width: "100%" }}
                      onChange={onAreaChange}
                      clearOnBlur={true}
                      disableClearable={true}
                      getOptionLabel={(option) => option.label}
                      disableCloseOnSelect={true}
                      defaultValue={formData.areas}
                      renderOption={(option, { selected }) => (
                        <Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                          />
                          {
                            areas.find((area) => area.value === option.value)
                              ?.label
                          }
                        </Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Area"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Location"
                      name="location"
                      className="TextField"
                      inputRef={refValues["Location"]}
                      key={keyReset}
                      defaultValue={formData.location}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {/* <KeyboardDatePicker
                    disableToolbar
                    label="Date"
                    required
                    variant="inline"
                    inputVariant="outlined"
                    format={FORMAT.DATE_TIME_CLIENT}
                    margin="10"
                    id="dateFrom"
                    value={formData.dateFrom}
                    autoOk={true}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    placeholder={FORMAT.DATE_TIME_CLIENT}
                    onChange={(date) => onDateFromPickerChange(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  /> */}
                    <TextField
                      id="date"
                      label="Date "
                      type="date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      variant="outlined"
                      name="Date"
                      // value={`${profile.DOB}`}
                      // placeholder={FORMAT.DATE_TIME_CLIENT}
                      onChange={(e) => onDateFromPickerChange(e)}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      key={keyReset}
                      defaultValue={formData.dateFrom}
                      required
                    />

                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["dateFrom"]}
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    {/* <KeyboardDatePicker
                    label="End Date"
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format={FORMAT.DATE_TIME_CLIENT}
                    id="dateTo"
                    value={formData.dateTo}
                    placeholder={FORMAT.DATE_TIME_CLIENT}
                    autoOk={true}
                    onChange={(date) => onDateToPickerChange(date)}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  /> */}
                    <TextField
                      id="dateTo"
                      label="End Date"
                      type="date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      variant="outlined"
                      name="dateTo"
                      // value={`${profile.DOB}`}
                      // placeholder={FORMAT.DATE_TIME_CLIENT}
                      // onChange={(date) => onDateToPickerChange(date)}
                      onChange={(e) => onDateToPickerChange(e)}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      key={keyReset}
                      defaultValue={formData.dateTo}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["dateTo"]}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    {isIE && isSafari ? (
                      <>
                        <KeyboardTimePicker
                          label="Time"
                          margin="normal"
                          id="hourFrom"
                          inputVariant="outlined"
                          value={currentHourFrom}
                          onChange={onCurrentHourFromChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          keyboardIcon={<ScheduleIcon />}
                        />
                      </>
                    ) : (
                        <TextField
                          label="Time"
                          type="time"
                          name="hourFrom"
                          variant="outlined"
                          className="TextField"
                          onChange={onHourFromChange}
                          //value={formData.hourFrom}
                          key={keyReset}
                          defaultValue={
                            formData.hourFrom !== "" &&
                              formData.hourFrom !== "12:00 AM"
                              ? convertFrom12To24Format(formData.hourFrom)
                              : ""
                          }
                        />
                      )}
                    {/* <span className="error" style={{ color: "red" }}>
                {fieldValidation.errors["hourFrom"]}
              </span> */}
                  </Grid>

                  <Grid item xs={6}>
                    {isIE && isSafari ? (
                      <>
                        <KeyboardTimePicker
                          margin="normal"
                          label="End Time"
                          id="hourTo"
                          inputVariant="outlined"
                          value={currentHourTo}
                          onChange={onCurrentHourToChange}
                          disabled={isHourToDisabled}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          keyboardIcon={<ScheduleIcon />}
                        />
                      </>
                    ) : (
                        <TextField
                          label="End Time"
                          type="time"
                          name="hourTo"
                          variant="outlined"
                          className="TextField"
                          onChange={onHourToChange}
                          //value={formData.hourTo}
                          disabled={isHourToDisabled}
                          defaultValue={
                            formData.hourTo !== "" &&
                              formData.hourTo !== "11:59 PM"
                              ? convertFrom12To24Format(formData.hourTo)
                              : ""
                          }
                          key={keyReset}
                        />
                      )}
                    {/* <span className="error" style={{ color: "red" }}>
                {fieldValidation.errors["hourTo"]}
              </span> */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="deadline"
                      label="Application Deadline "
                      type="date"
                      pattern="\d{4}-\d{2}-\d{2}"
                      variant="outlined"
                      name="deadline"
                      onChange={(e) => onDeadlinePickerChange(e)}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      key={keyReset}
                      defaultValue={formData.deadline}
                      required
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["deadline"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <label>
                      Pay (select one) <span style={{ color: "red" }}>*</span>
                    </label>
                    <RadioGroup
                      aria-label="amountOfPay"
                      name="amountOfPay"
                      value={formData.amountOfPay}
                      onChange={(e) => onDataChange(e)}
                    >
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={AMOUNTOFPAY.HOURLY}
                          control={<Radio color="primary" />}
                          label={AMOUNTOFPAY.HOURLY}
                        />
                        <div
                          className="amountOfPay"
                          style={{ display: "flex" }}
                          onClick={(e) =>
                            onCurrencyTextClick(e, AMOUNTOFPAY.HOURLY)
                          }
                          readOnly={formData.amountOfPay != AMOUNTOFPAY.HOURLY}
                        >
                          <CurrencyTextField
                            inputProps={{
                              inputMode: "numeric",
                            }}
                            type="text"
                            style={{ width: "80px", marginTop: 6 }}
                            value={hourlySalary}
                            currencySymbol="$"
                            onChange={(event, value) => {
                              setHourlySalary(value);
                              setFlatFeeSalary("");
                              setMonthlySalary("");
                              setDailySalary("");
                            }}
                          // autoFocus={
                          //   formData.amountOfPay == AMOUNTOFPAY.HOURLY
                          // }
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span
                            style={{
                              marginTop: "9px",
                            }}
                          >
                            per hour
                          </span>
                        </div>
                      </div>
                      <span className="error" style={{ color: "red" }}>
                        {fieldValidation.errors[SALARY_KEYS.HOURLY]}
                      </span>

                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={AMOUNTOFPAY.DAILY}
                          control={<Radio color="primary" />}
                          label={AMOUNTOFPAY.DAILY}
                        />
                        <div
                          className="amountOfPay"
                          style={{ display: "flex" }}
                          onClick={(e) =>
                            onCurrencyTextClick(e, AMOUNTOFPAY.DAILY)
                          }
                          readOnly={formData.amountOfPay != AMOUNTOFPAY.DAILY}
                        >
                          <CurrencyTextField
                            inputProps={{
                              inputMode: "numeric",
                            }}
                            type="text"
                            style={{ width: "80px", marginTop: 6 }}
                            value={dailySalary}
                            currencySymbol="$"
                            onChange={(event, value) => {
                              setDailySalary(value);
                              setHourlySalary("");
                              setMonthlySalary("");
                              setFlatFeeSalary("");
                            }}
                          // autoFocus={
                          //   formData.amountOfPay == AMOUNTOFPAY.DAILY
                          // }
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span
                            style={{
                              marginTop: "9px",
                            }}
                          >
                            per day
                          </span>
                        </div>
                      </div>

                      <span className="error" style={{ color: "red" }}>
                        {fieldValidation.errors[SALARY_KEYS.DAILY]}
                      </span>
                      {/* <div>MONTHLY</div> */}
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value={AMOUNTOFPAY.MONTHLY}
                          control={<Radio color="primary" />}
                          label={AMOUNTOFPAY.MONTHLY}
                        />
                        <div
                          className="amountOfPay"
                          style={{ display: "flex" }}
                          onClick={(e) =>
                            onCurrencyTextClick(e, AMOUNTOFPAY.MONTHLY)
                          }
                          readOnly={formData.amountOfPay != AMOUNTOFPAY.MONTHLY}
                        >
                          <CurrencyTextField
                            inputProps={{
                              inputMode: "numeric",
                            }}
                            type="text"
                            style={{ width: "80px", marginTop: 6 }}
                            value={monthlySalary}
                            currencySymbol="$"
                            onChange={(event, value) => {
                              setMonthlySalary(value);
                              setDailySalary("");
                              setHourlySalary("");
                              setFlatFeeSalary("");
                            }}
                          // autoFocus={
                          //   formData.amountOfPay == AMOUNTOFPAY.MONTHLY
                          // }
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span
                            style={{
                              marginTop: "9px",
                            }}
                          >
                            per month
                          </span>
                        </div>
                      </div>
                      <span className="error" style={{ color: "red" }}>
                        {fieldValidation.errors[SALARY_KEYS.MONTHLY]}
                      </span>
                      {/* <div>MONTHLY</div> */}
                      <div
                        style={{ display: "flex" }}
                        onClick={(e) =>
                          onCurrencyTextClick(e, AMOUNTOFPAY.FLAT_FEE)
                        }
                        readOnly={formData.amountOfPay != AMOUNTOFPAY.FLAT_FEE}
                      >
                        <FormControlLabel
                          value={AMOUNTOFPAY.FLAT_FEE}
                          control={<Radio color="primary" />}
                          label={AMOUNTOFPAY.FLAT_FEE}
                        />
                        <CurrencyTextField
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          type="text"
                          style={{ width: "80px", marginTop: 6 }}
                          value={flatFeeSalary}
                          currencySymbol="$"
                          onChange={(event, value) => {
                            setFlatFeeSalary(value);
                            setHourlySalary("");
                            setDailySalary("");
                            setMonthlySalary("");
                          }}
                        // autoFocus={
                        //   formData.amountOfPay == AMOUNTOFPAY.FLAT_FEE
                        // }
                        />
                      </div>
                      <span className="error" style={{ color: "red" }}>
                        {fieldValidation.errors[SALARY_KEYS.FLAT_FREE]}
                      </span>

                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value="Not stated"
                          control={<Radio color="primary" />}
                          label="Not stated"
                        />
                      </div>
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      id="paymentTerm"
                      key={keyReset}
                      options={paymentTerms}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Payment Term"
                          variant="outlined"
                        />
                      )}
                      disableClearable={true}
                      onChange={onPaymentTermChange}
                      defaultValue={formData.paymentTermObj}
                    />

                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["paymentTerm"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Attire"
                      multiple
                      name="attire"
                      className="TextField"
                      inputRef={refValues["Attire"]}
                      key={keyReset}
                      defaultValue={formData.attire}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      inputProps={{
                        inputMode: "numeric",
                      }}
                      type="text"
                      required
                      label="Contact Number "
                      variant="outlined"
                      name="contactNumber"
                      value={formData.contactNumber}
                      type="number"
                      className="TextField"
                      onChange={(e) => onDataChange(e)}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["contactNumber"]}
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      inputProps={{
                        inputMode: "email",
                      }}
                      type="text"
                      label="Contact Email"
                      variant="outlined"
                      name="contactEmail"
                      type="text"
                      className="TextField"
                      inputRef={refValues["ContactEmail"]}
                      key={keyReset}
                      defaultValue={formData.contactEmail}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {fieldValidation.errors["contactEmail"]}
                    </span>
                  </Grid>

                  <div className="btnWhatapp">
                    <Button
                      name="action"
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="Save"
                      onClick={(e) => onConfirm(e)}
                      style={{
                        width: "100%",
                        margin: "0 8px",
                      }}
                    >
                      {BUTTON_TEXT.PREVIEW}
                    </Button>
                  </div>
                </Grid>
              ) : null
            }
            {openErrorDialog && (
              <DialogComponent
                openState={openErrorDialog}
                handleClose={handleCloseErrorDialog}
                title={POST_GIG_FORM.DIALOG_MESSAGE.ERROR_TITLE}
                message={POST_GIG_FORM.DIALOG_MESSAGE.ERROR}
              />
            )}
            {openSuccessDialog && (
              <DialogComponent
                openState={openSuccessDialog}
                handleClose={handleCloseSuccessDialog}
                title={POST_GIG_FORM.DIALOG_MESSAGE.SUCCESS_TITLE}
                message={POST_GIG_FORM.DIALOG_MESSAGE.SUCCESS}
              />
            )}
            {openMissingUserInfoDialog ? (
              <MissingUserInfoDialog
                openDialog={openMissingUserInfoDialog}
                handleCloseDialog={handleCloseMissingUserInfoDialog}
                isGigBossNotification={true}
              />
            ) : null}
            {isShowPostJobMandatoryErrorDialog &&
              Object.keys(fieldValidation.errors).length > 0 ? (
                <PostJobErrorDialog
                  openDialog={isShowPostJobMandatoryErrorDialog}
                  closeDialog={handleCloseMandatoryErrorDialog}
                  errorMessages={fieldValidation.errors}
                />
              ) : null}
          </div>
        </Container>
      )}
      {isShowPostJobPreview && postJobPreviewData ? (
        <PostJobPreview
          data={postJobPreviewData}
          handleEditClick={handleClosePostJobPreview}
          resetForm={resetForm}
          handleOpenSuccessDialog={handleOpenSuccessDialog}
          handleOpenErrorDialog={handleOpenErrorDialog}
        />
      ) : null}
    </DarkMode>
  );
});

export default PostJob;
