import { Avatar, Container, Grid, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Business from "@material-ui/icons/Business";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PersonIcon from "@material-ui/icons/Person";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  DOMAIN,
  FORMAT,
  FORM_TYPES,
  JOB_FILTER_CONDITIONS,
  MESSAGE,
  POST_GIG_FORM,
  PROFILE,
  PROFILE_KEYS,
} from "../config/config";
import { trimValue } from "../ultils/ultils";
import EditComponent from "./Components/EditComponent";
import SocialComponent from "./Components/SocialComponent";
import ViewComponent from "./Components/ViewComponent";

import WrapperComponent from "./Components/WrapperComponent";
import JobsList from "./Jobs/JobsList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function NavTabs({
  isReadOnly,
  profile,
  handleChange,
  handleSelected,
  previewPhoto,
  companyImage,
  handleOpenEditModal,
  inValidUrl,
  username,
  handleClickUrl,
  handleClickSocial,
  handleOpen,
  errorObj,
  os,
  refValues,
  profileEdit,
}) {
  console.log({ profile });
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [gigAdsValue, setGigAdsValue] = React.useState(0);
  const [gigApplicationValue, setGigApplicationValue] = useState(0);
  const [allSkills, setAllSkills] = useState([]);
  let [selectedSkills, setSelectedSkills] = useState([]);
  const [page, setPage] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);

    Object.keys(refValues).forEach((key) => {
      if (refValues[key].current) {
        profile[key] = refValues[key].current.value;
        profileEdit[key] = refValues[key].current.value;
      }
    });
  };

  const handleChangeGigAdsTab = (event, newValue) => {
    setGigAdsValue(newValue);
  };
  const handleChangeGigApplicationTab = (event, newValue) => {
    setGigApplicationValue(newValue);
  };
  useEffect(() => {
    const fetchSkills = async () => {
      const getAllSkills = await axios.get(API.GET_ALL_SKILLS);
      console.log({ allSkills: getAllSkills.data });
      setAllSkills(getAllSkills.data);
    };

    fetchSkills();
  }, []);

  useEffect(() => {
    if (allSkills.length > 0) {
      let arr = [];
      Object.keys(allSkills).map((key) => {
        const indexKey = allSkills[key].Id.toString();
        let profileSkillIds = profile.SkillIds?.split(";") || [];
        if (profileSkillIds.indexOf(indexKey) !== -1) {
          arr.push(allSkills[key]);
        }
      });

      setSelectedSkills(arr);
    }
  }, [allSkills]);
  const getFullName = (profile) => {
    let fullName =
      profile.FirstName && profile.LastName
        ? `${profile.FirstName} ${profile.LastName}`
        : "";
    return fullName;
  };
  const renderSkills = (profile) => {
    let skillsField = profile.Skills.join(", ");
    return skillsField;
  };
  return (
    <Container maxWidth="sm" className="tabs">
      <div className={classes.root}>
        <div position="static">
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            value={value}
            onChange={handleChangeTab}
            aria-label="nav tabs example"
            className="_tabs"
          >
            <Tab
              className="txtTabs"
              icon={<PersonIcon />}
              label="Gigger"
              {...a11yProps(0)}
            />

            <Tab
              className="txtTabs"
              icon={<Business />}
              onClick={() => console.log("Clicked")}
              label="Gig Boss"
              {...a11yProps(1)}
            />
          </Tabs>
        </div>
        <TabPanel className="TabPanel" value={value} index={0}>
          <Grid container spacing={3}>
            {isReadOnly ? (
              <WrapperComponent>
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Gender"
                  value={`${PROFILE.GENDER_VALUES[profile.Gender] || ""}`}
                />
                <ViewComponent label="Email" value={profile.Email} />
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Phone Number"
                  value={profile.PhoneNumber}
                />
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Date Of Birth"
                  value={
                    !username
                      ? profile.DOB &&
                        moment(profile.DOB).format(FORMAT.DATE_TIME_CLIENT)
                      : profile.DOB &&
                        moment(profile.DOB).format(FORMAT.DOB_DATE)
                  }
                />
                <SocialComponent
                  handleClickSocial={handleClickSocial}
                  profile={profile}
                />
                {/* <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Preferred Gig Types"
                  subLabel="(To receive alerts)"
                  value={renderSkills(profile)}
                  lastComponent={true}
                /> */}
              </WrapperComponent>
            ) : (
              <WrapperComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: os === "ios" ? "on" : "words",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="FirstName"
                    type="text"
                    label="First Name"
                    name="FirstName"
                    className="TextField"
                    // defaultValue="123456789"
                    defaultValue={profile.FirstName}
                    inputRef={refValues["FirstName"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: os === "ios" ? "on" : "words",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="LastName"
                    type="text"
                    label="Last Name"
                    name="LastName"
                    className="TextField"
                    // defaultValue="123456789"
                    defaultValue={profile.LastName}
                    inputRef={refValues["LastName"]}
                  />
                </EditComponent>
                <EditComponent>
                  <Autocomplete
                    id="combo-box-demo"
                    options={PROFILE.GENDER}
                    getOptionLabel={(option) => option.label}
                    color="primary"
                    value={PROFILE.GENDER.find(
                      (item) => item.value == profile.Gender
                    )}
                    // inputValue={PROFILE.GENDER_VALUES[profile.Gender]}
                    className="TextField"
                    onChange={(event, { value }) => {
                      const type = PROFILE_KEYS.GENDER;
                      handleSelected(type, value);
                    }}
                    // onInputChange={(event, newInputValue) => {
                    //   console.log({ newInputValue });
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gender"
                        variant="outlined"
                      />
                    )}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: "none",
                      inputMode: "email",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="Email"
                    type="text"
                    label="Email"
                    name="Email"
                    className="TextField"
                    // defaultValue="123456789"
                    error={errorObj.invalidEmail}
                    helperText={
                      errorObj.invalidEmail && errorObj.message
                        ? errorObj.message
                        : ""
                    }
                    defaultValue={profile.Email}
                    inputRef={refValues["Email"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      inputMode: "tel",
                      maxLength: "8",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="PhoneNumber"
                    label="Phone Number *"
                    name="PhoneNumber"
                    className="TextField"
                    type="text"
                    error={
                      profile.PhoneNumber.length < 8 ||
                      profile.PhoneNumber.length > 8
                    }
                    helperText={
                      profile.PhoneNumber.length === 8
                        ? ""
                        : POST_GIG_FORM.ERROR_MESSAGE.PHONE_NUMBER_MAX_LENGTH
                    }
                    // defaultValue="123456789"
                    defaultValue={profile.PhoneNumber}
                    inputRef={refValues["PhoneNumber"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    id="date"
                    label="Date of Birth"
                    type="date"
                    pattern="\d{4}-\d{2}-\d{2}"
                    variant="outlined"
                    name="DOB"
                    value={`${
                      profile.DOB
                        ? moment(profile.DOB).format(FORMAT.DATE_TIME_SERVER)
                        : moment()
                            .subtract(18, "year")
                            .format(FORMAT.DATE_TIME_SERVER)
                    }`}
                    placeholder={""}
                    onChange={(e) => handleChange(e)}
                    className="TextField"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </EditComponent>
                <WrapperComponent mark="Social Component">
                  <EditComponent>
                    <TextField
                      inputProps={{
                        readOnly: isReadOnly,
                        autoCapitalize: "none",
                        inputMode: "url",
                      }}
                      id="instagram"
                      label="Instagram"
                      type="text"
                      variant="outlined"
                      name="InstagramLink"
                      placeholder={""}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        profile.InstagramLink
                          ? trimValue(profile.InstagramLink)
                          : ""
                      }
                      inputRef={refValues["InstagramLink"]}
                    />
                  </EditComponent>
                  <EditComponent>
                    <TextField
                      inputProps={{
                        readOnly: isReadOnly,
                        autoCapitalize: "none",
                        inputMode: "url",
                      }}
                      id="twitter"
                      label="Twitter"
                      type="text"
                      variant="outlined"
                      name="TwitterLink"
                      placeholder={""}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        profile.TwitterLink
                          ? trimValue(profile.TwitterLink)
                          : ""
                      }
                      inputRef={refValues["TwitterLink"]}
                    />
                  </EditComponent>
                  <EditComponent>
                    <TextField
                      inputProps={{
                        readOnly: isReadOnly,
                        autoCapitalize: "none",
                        inputMode: "url",
                      }}
                      id="facebook"
                      label="Facebook"
                      type="text"
                      variant="outlined"
                      name="FacebookLink"
                      placeholder={""}
                      className="TextField"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        profile.FacebookLink
                          ? trimValue(profile.FacebookLink)
                          : ""
                      }
                      inputRef={refValues["FacebookLink"]}
                    />
                  </EditComponent>
                  <EditComponent>
                    <TextField
                      inputProps={{
                        readOnly: isReadOnly,
                        autoCapitalize: "none",
                        inputMode: "url",
                      }}
                      id="website"
                      label="Website"
                      type="text"
                      variant="outlined"
                      name="WebsiteLink"
                      error={errorObj.invalidUrl}
                      helperText={
                        errorObj.invalidUrl ? MESSAGE.INVALID_URL : ""
                      }
                      placeholder={""}
                      className="TextField"
                      defaultValue={profile.WebsiteLink}
                      inputRef={refValues["WebsiteLink"]}
                    />
                  </EditComponent>
                </WrapperComponent>

                {/* <EditComponent>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    className="TextField"
                    options={allSkills}
                    style={{ width: "maxWidth" }}
                    disabled={isReadOnly}
                    disableCloseOnSelect
                    value={selectedSkills}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    onChange={(event, value) => {
                      console.log("Autocomplete");
                      let type = PROFILE_KEYS.SKILLS;
                      let skills = [];
                      value.map((item) => {
                        skills.push(item);
                      });
                      console.log({ ArraySkilsl: skills });
                      setSelectedSkills(value);
                      handleSelected(type, skills);
                    }}
                    getOptionLabel={(option) => option.Name}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          //checked={profile.Skills.indexOf(option.SkillName) !== -1}
                          checked={selected}
                          color="primary"
                        />
                        {option.Name}
                      </Fragment>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          color="primary"
                          // InputProps={{ props: [] }}
                          variant="outlined"
                          label="Preferred Gig Types"
                          className="TextField"
                        />
                      );
                    }}
                  />
                </EditComponent> */}
              </WrapperComponent>
            )}

            {/* <Grid item xs={12}>
              {isReadOnly ? (
                <TextField
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                  disabled={isReadOnly}
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  variant="outlined"
                  id="MaritalStatus"
                  label="Marital Status"
                  name="MaritalStatus"
                  className="TextField"
                  // defaultValue="123456789"
                  value={`${
                    PROFILE.MARITAL_VALUES[profile.MaritalStatus] || ""
                  }`}
                />
              ) : (
                <Autocomplete
                  id="combo-box-demo"
                  options={PROFILE.MARTIAL_STATUS}
                  onChange={(event, { value }) => {
                    const type = PROFILE_KEYS.MARTIAL_STATUS;
                    handleSelected(type, value);
                  }}
                  className="TextField"
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marital Status"
                      variant="outlined"
                      className="TextField"
                    />
                  )}
                />
              )}
            </Grid> */}
          </Grid>
        </TabPanel>
        <TabPanel className="TabPanel" value={value} index={1}>
          <div className="avatarUser" style={{ margin: "0 0 20px" }}>
            {isReadOnly ? (
              <Avatar
                alt={profile.username}
                onClick={handleOpen}
                src={
                  previewPhoto["CompanyImage"]
                    ? previewPhoto["CompanyImage"]
                    : "/static/images/avatar/1.jpg"
                    ? `${DOMAIN}profile/${companyImage}`
                    : "/static/images/avatar/1.jpg"
                }
                //src={"/static/images/avatar/1.jpg"}
                className="avatar"
              />
            ) : (
              <Fragment>
                <Avatar
                  alt={profile.username}
                  src={
                    previewPhoto["CompanyImage"]
                      ? previewPhoto["CompanyImage"]
                      : "/static/images/avatar/1.jpg"
                      ? `${DOMAIN}profile/${companyImage}`
                      : "/static/images/avatar/1.jpg"
                  }
                  //src={"/static/images/avatar/1.jpg"}
                  className="avatar"
                />
                <input
                  type="file"
                  className="file"
                  name="CompanyImage"
                  accept="image/*"
                  //accept="image/*;capture=camera"
                  onChange={(e) =>
                    handleChange(e, FORM_TYPES.FILE, "CompanyImage")
                  }
                />
              </Fragment>
            )}
          </div>

          <Grid container spacing={3}>
            {isReadOnly ? (
              <WrapperComponent>
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Company Name"
                  value={profile.CompanyName || ""}
                />
                <ViewComponent
                  onClick={
                    isReadOnly && !username
                      ? handleOpenEditModal
                      : handleClickUrl
                  }
                  label="Website"
                  value={profile.Website || ""}
                />
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Contact Name"
                  value={profile.ContactName || ""}
                />
                <ViewComponent
                  onClick={isReadOnly ? handleOpenEditModal : null}
                  label="Address"
                  value={profile.Address || ""}
                  lastComponent={true}
                />
              </WrapperComponent>
            ) : (
              <WrapperComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: os === "ios" ? "on" : "words",
                    }}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    disabled={isReadOnly}
                    variant="outlined"
                    id="name"
                    type="text"
                    name="CompanyName"
                    label="Company Name"
                    className="TextField"
                    defaultValue={profile.CompanyName}
                    inputRef={refValues["CompanyName"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: "none",
                      inputMode: "url",
                    }}
                    disabled={isReadOnly}
                    variant="outlined"
                    id="name"
                    name="Website"
                    label="Website"
                    type="text"
                    className="TextField"
                    error={errorObj.invalidUrl}
                    helperText={errorObj.invalidUrl ? MESSAGE.INVALID_URL : ""}
                    defaultValue={profile.Website}
                    inputRef={refValues["Website"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: os === "ios" ? "on" : "words",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="name"
                    type="text"
                    name="ContactName"
                    label="Contact Name"
                    className="TextField"
                    defaultValue={profile.ContactName}
                    inputRef={refValues["ContactName"]}
                  />
                </EditComponent>
                <EditComponent>
                  <TextField
                    inputProps={{
                      readOnly: isReadOnly,
                      autoCapitalize: os === "ios" ? "on" : "words",
                    }}
                    disabled={isReadOnly}
                    onClick={isReadOnly ? handleOpenEditModal : null}
                    variant="outlined"
                    id="Address"
                    label="Address"
                    type="text"
                    name="Address"
                    className="TextField"
                    defaultValue={profile.Address}
                    inputRef={refValues["Address"]}
                  />
                </EditComponent>
              </WrapperComponent>
            )}
          </Grid>
          {username ? (
            <Grid className="gigChild">
              <h6>Gig Ads</h6>
              <Tabs
                indicatorColor="primary"
                variant="fullWidth"
                value={gigAdsValue}
                onChange={handleChangeGigAdsTab}
                aria-label="nav tabs example"
                className="tabschild"
              >
                <Tab label="Active" {...a11yProps(0)} />
                <Tab label="Expired" {...a11yProps(1)} />
              </Tabs>
              <TabPanel className="box" value={gigAdsValue} index={0}>
                <JobsList
                  filterCondition={JOB_FILTER_CONDITIONS.GIG_BOSS_ACTIVE_JOBS}
                  giggerId={profile.Id}
                />
              </TabPanel>
              <TabPanel className="box" value={gigAdsValue} index={1}>
                <JobsList
                  filterCondition={JOB_FILTER_CONDITIONS.GIG_BOSS_EXPIRED_JOBS}
                  giggerId={profile.Id}
                />
              </TabPanel>
            </Grid>
          ) : null}
        </TabPanel>
      </div>
    </Container>
  );
}
