import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GIGSOMETHING_SG, WEBVIEW_ACTION } from "../config/config";
import { sendMessage } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";

export const NoInternet = (props) => {
  const { onApp } = props.match.params;
  const [darkState, setDarkState] = useState(null);
  useEffect(() => {
    if (onApp) {
      sendMessage({
        action: WEBVIEW_ACTION.UPDATE_TITLE,
        title: "Page Not Found",
      });
    }
  }, [onApp]);
  const handleClose = () => {
    //alert(onApp);
    if (onApp) {
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
        open: false,
      });
    } else {
      window.location.href = GIGSOMETHING_SG;
    }
  };
  return (
    <DarkMode darkState={darkState}>
      <div className="errorPage">
        <div className="content">
          <div className="icon"></div>

          <h4 style={{ textTransform: "none" }}>
            No internet connection found, <br />
            Please check your Internet Setting
          </h4>
          <Button
            name="action"
            type="submit"
            variant="contained"
            color="primary"
            value="Return to Home"
            onClick={handleClose}
          >
            Try Again
          </Button>
        </div>
      </div>
    </DarkMode>
  );
};

export default NoInternet;
